import React from 'react'

import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"



const RadioField = ({name, label, initialValue, choices, type, helpText})=>{

  return <div className="mb-3">

    <Field name={name}>
    {({input, meta})=>{

      return <>
        <label>{label}</label>
        {choices.map((choice, index)=>{
          const fieldId = `input-${name}-${index}`

          return <Field name={name} type={type} value={choice.id} key={index} initialValue={initialValue}>
            {({input, meta}) => (

              <div className="form-check">
                <input type={type} className="form-check-input" id={fieldId} {...input}/>
                <label className="form-check-label" htmlFor={fieldId}>{choice.label}</label>
              </div>

            )}
          </Field>
        })}

        {(meta.error || meta.submitError) && meta.touched &&(
          <div className="invalid-feedback">{String(meta.error || meta.submitError)}</div>
        )}

        {helpText && <small className="form-text text-muted">{helpText}</small>}

      </>
    }}
    </Field>
  </div>


}

export default RadioField
