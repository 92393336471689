
import React from 'react';
import {Field} from 'react-final-form'

const Condition = ({ when, is, children, compare }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => ((compare ? compare(value) : value === is) ? children : null)}
  </Field>
)

export default Condition
