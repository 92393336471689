import { v4 as uuidv4 } from 'uuid';

import { useDrop } from 'react-dnd'



const ChannelDropContext = ({fields, children})=> {

  const [{ isOver }, drop] = useDrop({
    accept: ["media", "app", "playlist"],

    drop: async (item, monitor) => {

      const TYPE_TO_CONTENT_TYPE = {
        app: "app_instance",
        media: "media",
        playlist: "playlist"
      }
      // TODO: we should also support an actual app-instance...
      fields.unshift({
        content_type: TYPE_TO_CONTENT_TYPE[item.type],

        duration:10,
        scale: "fit",
        left: 0,
        top: 0,
        width: 1920/2,
        height: 1080/2,

        _uuid: uuidv4(), // until we get a db id we use this as key

        media: item.media,
        app: item.app,
        playlist: item.playlist,
      })

    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })

  return children({isOver, dropRef:drop})

}


export default ChannelDropContext
