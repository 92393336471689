import React from "react";


import {ContentThumbnail} from "../index"


const ContentPreviewItem = ({content, tag, noThumbnail, children})=>{
  return <>
    {!noThumbnail &&
      <ContentThumbnail thumbnail={content.thumbnail} className="mr-2"/>
    }
    <div>
      <div className="badge badge-dark">{tag}</div>
      <div className="content-label">{content.is_deleted ? <del>{content.name}</del> : content.name}  {children && children}</div>
    </div>
  </>
}

const ContentPreview = ({ data }) => {
  return (
    <>
      {data.media && (
        <ContentPreviewItem content={data.media} tag="MEDIA">
          <span className="badge badge-secondary mx-2">
            {data.media.width}x{data.media.height}
          </span>
        </ContentPreviewItem>
      )}

      {data.canvas && (
        <ContentPreviewItem content={data.canvas} tag="CANVAS"/>
      )}

      {data.link && (
        <ContentPreviewItem content={data.link} tag="LINK"/>
      )}

      {data.app_instance && (
        <ContentPreviewItem content={data.app_instance} tag="APP"/>
      )}

      {data.app && (
        <ContentPreviewItem content={data.app} noThumbnail={true} tag="APP"/>
      )}

      {data.playlist && (
        <ContentPreviewItem content={data.playlist} tag="PLAYLIST"/>
      )}

      {data.content_type === "placeholder" && (
        <ContentPreviewItem tag="PLACEHOLDER" content={{name: ""}} noThumbnail={true}/>
      )}

      {!data.content_type && (
        <span className="muted">
          <strong>Drop content here</strong>
        </span>
      )}
    </>
  );
};

export default ContentPreview;
