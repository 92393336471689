import React, { useState, useEffect } from 'react'

import AsyncSelect from 'react-select/async';
import service from "../../service"


const colors = {
  /*
   * multiValue(remove)/color:hover
   */
  // danger: 'var(--danger)',

  /*
   * multiValue(remove)/backgroundColor(focused)
   * multiValue(remove)/backgroundColor:hover
   */
  // dangerLight: 'var(--danger-light)',

  /*
   * control/backgroundColor
   * menu/backgroundColor
   * option/color(selected)
   */
  neutral0: "var(--neutral-0)",

  /*
   * control/backgroundColor(disabled)
   */
  // neutral5: 'var(--neutral-5)',

  /*
   * control/borderColor(disabled)
   * multiValue/backgroundColor
   * indicators(separator)/backgroundColor(disabled)
   */
  neutral10: "var(--secondary)",

  /*
   * control/borderColor
   * option/color(disabled)
   * indicators/color
   * indicators(separator)/backgroundColor
   * indicators(loading)/color
   */
  // neutral20: 'var(--neutral-20)',

  /*
   * control/borderColor(focused)
   * control/borderColor:hover
   */
  neutral30: "var(--neutral-30)",

  /*
   * menu(notice)/color
   * singleValue/color(disabled)
   * indicators/color:hover
   */
  // neutral40: 'var(--neutral-40)',

  /*
   * placeholder/color
   */
  // neutral50: 'var(--neutral-50)',

  /*
   * indicators/color(focused)
   * indicators(loading)/color(focused)
   */
  // neutral60: 'var(--neutral-60)',

  // neutral70: 'var(--neutral-70)',

  /*
   * input/color
   * multiValue(label)/color
   * singleValue/color
   * indicators/color(focused)
   * indicators/color:hover(focused)
   */
  neutral80: "var(--neutral-80)",

  neutral90: "var(--neutral-90)",

  /*
   * control/boxShadow(focused)
   * control/borderColor(focused)
   * control/borderColor:hover(focused)
   * option/backgroundColor(selected)
   * option/backgroundColor:active(selected)
   */
  primary: "var(--primary)",

  /*
   * option/backgroundColor(focused)
   */
  primary25: "var(--primary)",

  /*
   * option/backgroundColor:active
   */
  // primary50: "var(--primary)",

  // primary75: "var(--primary)",
};


export default class RelatedFilter extends React.Component {

  constructor(props) {
    super(props);


    this.state = {
      inputValue: '',
      defaultOptions: null
    };
    this.onChange = this.onChange.bind(this)
    this.search = this.search.bind(this)
    this.onMenuOpen = this.onMenuOpen.bind(this)
  }


  onMenuOpen(){
    console.log("onMenuOpen")

    if(this.state.defaultOptions === null){
      console.log("load default")
      this.search().then((options)=>{
        this.setState({defaultOptions: options})
      })
    }

  }

  async search(inputValue){



      let params;

      if(this.props.params){
        params = Object.assign({}, this.props.params)
      }else{
        params = {}
      }

      if(inputValue){
        params.search = inputValue
      }

      if(! params.fields){
        params.fields = "id,name"
      }



      let response = await service.list(this.props.filterConfig.resource, params)

      return response.data.results

  }

  onChange(newValue) {

    // const inputValue = newValue.replace(/\W/g, '');
    // console.log(newValue)
    // this.setState({ inputValue });
    console.log(newValue)
    // return inputValue;

  };

  render() {

    let optionLabel = this.props.optionLabel || "name"

    const defaultGetOptionValue = (option)=>option["id"]
    let getOptionValue = this.props.getOptionValue || defaultGetOptionValue

    return <AsyncSelect
            className="related-filter flex-fill"
            cacheOptions
            // defaultOptions
            onMenuOpen={this.onMenuOpen}
            defaultOptions={this.state.defaultOptions}
            loadOptions={this.search}
            theme={(theme) => {
              // console.log(theme)
              return {
                ...theme,
                // borderRadius: 0,
                colors: {
                  ...theme.colors,
                  ...colors,
                  // primary25: 'hotpink',
                  // primary: 'black',
                },
              };
            }}


            value={this.props.value || null}
            onChange={(value)=>this.props.updateFilters(this.props.filterConfig.name, value)}
            getOptionLabel={(option)=>option.name}
            getOptionValue={(option)=>option.id}
            placeholder={this.props.filterConfig.label}

            isClearable={true}

            {...this.props}

          />


  }
}



// const RelatedFilter = ({filterConfig, value, updateFilters})=>{
//
//
//   // if(inputValue){
//   //   params.search = inputValue
//   // }
//   //
//   // if(! params.fields){
//   //   params.fields = "id,name"
//   // }
//   //
//   //
//   //
//   // let response = await service.list(this.props.resource, params)
//
//   return <AsyncSelect
//
//     cacheOptions
//     loadOptions={async (inputValue)=>{
//       let response = await service.autocomplete(filterConfig.resource, {search: inputValue})
//
//       return response.data.results
//     }}
//     defaultOptions
//     value={value || null}
//     onChange={(value)=>updateFilters(filterConfig.name, value)}
//     getOptionLabel={(option)=>option.name}
//     getOptionValue={(option)=>option.id}
//     placeholder={filterConfig.label}
//     isClearable={true}
//   />
// }


// export default RelatedFilter
