
import React from "react";

import { Rnd } from "react-rnd";
import { FieldArray } from "react-final-form-arrays";
import { debounce } from 'throttle-debounce';

import AppPreview from "../preview"
import ChannelDropContext from "./drop.js"


const MediaArea = ({data, thumbnail})=>{
  return <div style={{
    "backgroundImage": `url(${thumbnail})`,
    "backgroundPosition": "center",
    "backgroundRepeat":"no-repeat",
    "backgroundSize": data.scale === "fit" ? "contain" : "cover"
  }} className="message-preview"/>
}


const FramesetScreen = ({name, width, height})=>{
    const [ref, setRef] = React.useState()

    const [elementWidth, setElementWidth] = React.useState(100)


    React.useEffect(()=>{
      if(ref){
        setElementWidth(ref.clientWidth)
      }
    }, [ref])


    const updateHeight = React.useCallback((event)=>{
      if(ref){
        setElementWidth(ref.clientWidth)
      }
    }, [ref])


    React.useEffect(()=>{
      window.addEventListener("resize", updateHeight)

      return ()=>{
        window.removeEventListener("resize", updateHeight);
      }

    }, [updateHeight])



    //
    // TODO:
    // 1. add draggable list
    // 2. toolbar: add app, add media, add placeholder
    // 3. add dialog to edit app fields
    // 4. save values based 1920x1080
    //

    const aspect = Number(width)/Number(height)


    let canvasWidth = elementWidth
    let canvasHeight = elementWidth/aspect

    const MAX_HEIGHT = 700.0
    if(canvasHeight > MAX_HEIGHT){
      let scale = MAX_HEIGHT/canvasHeight
      canvasHeight *= scale
      canvasWidth *= scale
    }

    const toStore = Number(width)/canvasWidth
    const fromStore = 1/toStore

    return (

    <FieldArray name={name}>
    {({ fields, meta }) => (

    <ChannelDropContext fields={fields}>
      {({isOver, dropRef})=>(
        <div ref={dropRef} className="p-2">

      <div className="" ref={setRef}>

      {elementWidth &&
      <div className="frameset" style={{width: canvasWidth, height: canvasHeight}} >
{
          fields.map((name, index)=>{

            const data = fields.value[index]
            if(data.delete){
              return null
            }

            return <Rnd
                key={data.id || data._uuid}
                bounds="parent"
                style={{zIndex: 99-index}}
                size={{
                  width: data.width*fromStore,
                  height: data.height*fromStore,
                }}
                position={{
                  x: data.left*fromStore,
                  y: data.top*fromStore,
                }}

                onDragStop={(e, d) => {
                  const newData = {...data}
                  newData.left = Math.round(d.x * toStore)
                  newData.top = Math.round(d.y * toStore)
                  fields.update(index, newData)
                }}

                onResize={(e, direction, ref, delta, position) => {

                  const newData = {
                    ...data,
                    width: Math.round(ref.offsetWidth * toStore),
                    height: Math.round(ref.offsetHeight * toStore),
                    left: Math.round(position.x * toStore),
                    top: Math.round(position.y * toStore),
                  }
                  fields.update(index, newData)

                }}
              >

                {data.media && <MediaArea thumbnail={data.media.thumbnail} data={data}/>}
                {data.playlist && data.playlist.thumbnail[0] && <MediaArea thumbnail={data.playlist.thumbnail[0]} data={data}/>}

                {data.app && <AppPreview name={`${name}.data`} app={data.app.current_version}/>}
                {data.content_type === "placeholder" && <div className="d-flex align-items-center justify-content-center h-100 w-100">PLACEHOLDER</div>}
              </Rnd>


          })
}



      </div>
    }
    </div>
  </div>
  )}
  </ChannelDropContext>
)}
</FieldArray>
)
}


export default FramesetScreen
