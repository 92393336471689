export default [



  {
    _tag: 'CSidebarNavItem',
    name: 'Media',
    to: '/media',
    auth: 'media',
    icon: 'cil-image',
  },


  {
    _tag: 'CSidebarNavItem',
    name: 'Apps',
    to: '/app-instances',
    auth: 'app-instances',
    icon: 'cil-object-group',
  },


  {
    _tag: 'CSidebarNavItem',
    name: 'Canvas',
    to: '/canvas',
    auth: 'canvas',
    icon: 'cil-object-ungroup',
  },


  {
    _tag: 'CSidebarNavItem',
    name: 'Links',
    to: '/links',
    auth: 'links',
    icon: 'cil-link',
  },

  {
    _tag: 'CSidebarNavItem',
    name: 'Playlists',
    to: '/playlists',
    auth: 'playlists',
    icon: 'cil-list-rich',
  },


  {
    _tag: 'CSidebarNavDivider',
    className: 'mt-2'
  },


  {
    _tag: 'CSidebarNavItem',
    name: 'Cast',
    to: '/casts',
    auth: 'casts',
    icon: 'cil-rss',
  },

  {
    _tag: 'CSidebarNavItem',
    name: 'Channels',
    to: '/channels',
    auth: 'channels',
    icon: 'cil-library',
  },


  {
    _tag: 'CSidebarNavItem',
    name: 'Screens',
    to: '/screens',
    auth: 'screens',
    icon: 'cil-screen-desktop',
  },
  {
    _tag: 'CSidebarNavDivider',
    className: 'mt-2'
  },


  {
    _tag: 'CSidebarNavDropdown',
    name: 'Settings',
    route: '/base',
    icon: 'cil-settings',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Screen groups',
        to: '/screen-groups',
        auth: 'screen-groups',
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Screen locations',
      //   to: '/screen-locations',
      //   auth: 'screen-locations',
      // },
      {
        _tag: 'CSidebarNavItem',
        name: 'Screen settings',
        to: '/screen-settings',
        auth: 'screen-settings',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Zones',
        to: '/zones',
        auth: 'zones',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Themes',
        to: '/themes',
        auth: 'themes',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Fonts',
        to: '/fonts',
        auth: 'fonts',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Tags',
        to: '/tags',
        auth: 'tags',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Folders',
        to: '/media-folders',
        auth: 'media-folders',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Custom apps',
        to: '/custom-apps',
        auth: 'custom-apps',
      },

      {
        _tag: 'CSidebarNavItem',
        name: 'Apps',
        to: '/apps',
        auth: 'apps',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Users',
        to: '/users',
        auth: 'users',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Accounts',
        to: '/accounts',
        auth: 'accounts',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Organizations',
        to: '/organizations',
        auth: 'organizations',
      },


      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Billing',
      //   to: '/billing',
      //   auth: 'accounts',
      // },

      {
        _tag: 'CSidebarNavItem',
        name: 'Emails',
        to: '/emails',
        auth: 'accounts',
      },

      {
        _tag: 'CSidebarNavItem',
        name: 'Invitations',
        to: '/invitations',
        auth: 'accounts',
      }


    ],
  },

  {
    _tag: 'CSidebarNavDivider',
    className: 'mt-5'
  },



  {
    _tag: 'CSidebarNavItem',
    name: 'Support',
    to: '/support',
    icon: 'cil-speech',
  },

]
