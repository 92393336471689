
import {
  cilListFilter,
  cilPlus,
  cilDataTransferDown,
  cilArrowTop,
  cilArrowBottom,
  cilArrowLeft,
  cilArrowRight,
  cilScreenDesktop,
  cilRss,
  cilImage,
  cilMediaPlay,
  cilMediaPause,
  cilCalendar,
  cilMenu,
  cilSettings,
  cilOptions,
  cilSwapVertical,
  cilTrash,
  cilActionUndo,
  cilPin,
  cilCloudUpload,
  cilSave,
  cilApps,
  cilClone,

  cilLibrary,
  cilListRich,


  cilFont,
  cilSquare,
  cilPencil,//edit
  cilCheck,
  cilMap,
  cilFile,
  cilLink,
  cilCog,
  cilList,
  cilTextShapes, //shapes
  cilObjectGroup,
  cilObjectUngroup,
  cilPlaylistAdd,
  cilClock,


  // commerce
  cilTags,
  cilViewModule,
  cilCart,
  cilUser,
  cilLan,
  cilSpeedometer,
  cilSpeech,
  cilLockLocked,
  cilBrowser,
  cilX,
  cilPaperclip,
  cilColumns,
  cilExternalLink,

  cilXCircle,
  cilCheckCircle,


} from '@coreui/icons'


export const icons = {
  cilListFilter,
  cilPlus,
  cilDataTransferDown,
  cilArrowTop,
  cilArrowBottom,
  cilArrowLeft,
  cilArrowRight,
  cilScreenDesktop,
  cilRss,
  cilImage,
  cilMediaPlay,
  cilMediaPause,
  cilCalendar,
  cilMenu,
  cilSettings,
  cilOptions,
  cilSwapVertical,
  cilTrash,
  cilActionUndo,
  cilPin,
  cilCloudUpload,
  cilSave,
  cilApps,
  cilClone,


  cilFont,
  cilSquare,
  cilPencil,//edit
  cilCheck,
  cilMap,
  cilFile,
  cilLink,
  cilCog,
  cilList,
  cilTextShapes, //shapes
  cilObjectGroup,
  cilObjectUngroup,
  cilPlaylistAdd,
  cilClock,


  cilTags,
  cilViewModule,
  cilCart,
  cilUser,
  cilLan,
  cilSpeedometer,
  cilSpeech,
  cilLockLocked,
  cilBrowser,
  cilX,
  cilPaperclip,
  cilColumns,
  cilExternalLink,

  cilXCircle,
  cilCheckCircle,

  cilLibrary,
  cilListRich,

}
