

import React from 'react'
import { debounce } from 'throttle-debounce';

import { FieldArray } from "react-final-form-arrays";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Form, Field } from 'react-final-form'
import CIcon from '@coreui/icons-react'

import service from "admin-kit/service"

import BootstrapField from "admin-kit/components/fields/bootstrap"
import TextField from "admin-kit/components/fields/text"
import TextAreaField from "admin-kit/components/fields/textarea"
import CheckboxField from "admin-kit/components/fields/checkbox"

import ColorField from "admin-kit/components/fields/color"
import BooleanField from "admin-kit/components/fields/checkbox"
import MediaField from "admin-kit/components/fields/media"
import HtmlField from "admin-kit/components/fields/html"
import DateTimeField from "admin-kit/components/fields/datetime"
import DateField from "admin-kit/components/fields/date"

import LocationField from "admin-kit/components/fields/map"

import NumberField from "admin-kit/components/fields/number"
import {useTranslation} from "admin-kit/context/trans"

import { OnChange } from 'react-final-form-listeners'

import { v4 as uuidv4 } from 'uuid';

import AppPreview from "./preview"

import { useDrop } from 'react-dnd'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : null,
});


const makeOnDragEndFunction = fields => result => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  fields.move(result.source.index, result.destination.index);
};



const RepeatingField = ({name, field, label}) => {

    return<FieldArray name={name}>
    {({ fields, meta }) => (

      <BootstrapField label={""} meta={meta}>
       <>
        <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
          <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
            ref={provided.innerRef}
            className={"inline-items"}
            style={getListStyle(snapshot.isDraggingOver)}
            >
            {fields.map((name, index) => (
              <Draggable
              // key={name}
              // draggableId={name}
              key={fields.value[index]._uuid}
              draggableId={fields.value[index]._uuid}
              index={index}
              >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  className={`inline-item ${fields.value[index].delete ? "delete" : ""}`}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >

                <span {...provided.dragHandleProps} className="drag-handle"><CIcon name="cil-swap-vertical"/></span>

                <div className="inline-content">
                  <Fields fields={field.children} name={name} />

                </div>

                <div className="inline-tools">

                  <span onClick={() => {
                    fields.remove(index)
                  }} className="tool-btn">
                    <CIcon name="cil-trash"/>
                  </span>
                </div>


              </div>
              )}
              </Draggable>
            ))}

            {provided.placeholder}


            </div>

          )}
          </Droppable>
          </DragDropContext>

          <button
            type="button"
            onClick={() => {
              fields.push({_uuid: uuidv4() })
            }} className="btn btn-link">

            <CIcon name="cil-playlist-add" className="mr-1"/>

            Add new element in {label}
          </button>
          </>

      </BootstrapField>

    )}
    </FieldArray>



}


const GroupField = ({name, field, label, children}) => {

  const [open, setOpen] = React.useState(true)

  return <div className="group-field">
    <label>
      {label}
      <button type="button" onClick={()=>{setOpen(!open)}} className="btn btn-link btn-sm text-light"><i className={`fa fa-${open ? "chevron-down" : "chevron-right"} mx-1`}/></button>
    </label>


      <div className={`group-fields layout-${(field.attributes && field.attributes.layout)  ?  field.attributes.layout : "default"}`}>
      {open &&
        (children ? children: <Fields fields={field.children} name={name} />)
      }
      </div>
  </div>

}



const Fields = ({name, fields})=>{

  return fields.map((field, index)=>{


    if(!field.attributes.id){
      return <div key={index}>-</div>
    }

    const fieldId = `${name}.${field.attributes.id}`
    const fieldLabel = field.attributes.name || field.attributes.id
    const attributes = field.attributes;

    if(field.type === "text_field"){

      let choices
      if(field.attributes.predefined_values){
        choices = field.attributes.predefined_values.map(value=>({value: value, label: value}))
      }

      return <TextField key={index} name={fieldId} label={fieldLabel} choices ={choices} required={attributes.required}/>

    }else if(field.type === "number_field"){

      return <NumberField key={index} name={fieldId} label={fieldLabel} type={attributes.slider ? "range": "number"} min={attributes.min} max={attributes.max} step={attributes.step} required={attributes.required} />

    }else if(field.type === "richtext_field"){
      return <HtmlField key={index} name={fieldId} label={fieldLabel}/>

    }else if(field.type === "color_field"){
      return <ColorField key={index} name={fieldId} label={fieldLabel} field={field}/>

    }else if(field.type === "datetime_field"){
      return <DateTimeField key={index} name={fieldId} label={fieldLabel} field={field}/>

    }else if(field.type === "date_field"){
      return <DateField key={index} name={fieldId} label={fieldLabel} field={field}/>

    }else if(field.type === "media_field"){
      return <MediaField key={index} name={fieldId} label={fieldLabel} field={field}/>

    }else if(field.type === "boolean_field"){
      return <BooleanField key={index} name={fieldId} label={fieldLabel} field={field}/>

    }else if(field.type === "location_field"){
      return <LocationField key={index} name={fieldId} label={fieldLabel} field={field}/>

    }else if(field.type === "repeating_group"){
      return <GroupField key={index} name={fieldId} label={fieldLabel} field={field}>
        <RepeatingField name={fieldId} label={fieldLabel} field={field}/>
      </GroupField>

    }else if(field.type === "group"){
      return <GroupField key={index} name={fieldId} label={fieldLabel} field={field}/>

    }else {
      return <div key={index}><span className="badge">{fieldLabel} - {field.type}</span></div>
    }

  })

}


const AppInstanceField = ({name, label, app, show_preview})=>{

  const {t} = useTranslation()

  return <div className="message-field mb-2">
      <label>{label}</label>

      {app.data &&
      <div className="row">

        <div className="col-md-6 col-12">
          <Fields name={name} fields={app.data.content}/>
        </div>

        {show_preview && app.preview_url &&
        <div className="col-md-6 col-12">
          <label>{t("Preview")}</label>
          <div className="card p-1 preview-card">
            <div className="preview-wrapper">
              <AppPreview name={name} app={app}/>
            </div>
          </div>
        </div>
        }
      </div>
      }
    </div>

}


export default AppInstanceField

export {Fields}
