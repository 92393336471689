import React from 'react';

import { Field } from 'react-final-form'

import GenericDetail, {DetailBody} from "admin-kit/resources/base/detail"

import service from  "admin-kit/service"


import AppInstanceField from "../../components/fields/app-instance"

import {AppSelectDetail} from "../app-instance/detail"



const ThemeBody = ({resource, initialValues, ...props})=>{


  return <DetailBody resource={resource} {...props} customRenderField={({field, prefix, resource, renderField})=>{

    if(field.type === "message"){
      if(initialValues){

        let app
        if(initialValues.app){
          app = initialValues.app.current_version
        }else{
          app = initialValues.custom_app
        }
        app.data = app.theme_data

        return <AppInstanceField name={field.name} label={field.label} key={field.name} app={app}/>
      }else{
        return null
      }

    }

    return renderField({field, prefix, resource})

  }}/>

}






const ThemeDetail = (props)=>{
  return  <AppSelectDetail {...props} BodyComponent={ThemeBody}/>
}


const ThemeEdit = (props)=>{

  return <GenericDetail {...props} BodyComponent={ThemeBody}/>
}


export default ThemeDetail
export {ThemeEdit}
