import React, { Component } from "react";

import AsyncSelect from "react-select/async";
import service from "../../service";
import { Field } from "react-final-form";
import BootstrapField from "./bootstrap";

const colors = {
  /*
   * multiValue(remove)/color:hover
   */
  // danger: 'var(--danger)',

  /*
   * multiValue(remove)/backgroundColor(focused)
   * multiValue(remove)/backgroundColor:hover
   */
  // dangerLight: 'var(--danger-light)',

  /*
   * control/backgroundColor
   * menu/backgroundColor
   * option/color(selected)
   */
  neutral0: "var(--neutral-0)",

  /*
   * control/backgroundColor(disabled)
   */
  // neutral5: 'var(--neutral-5)',

  /*
   * control/borderColor(disabled)
   * multiValue/backgroundColor
   * indicators(separator)/backgroundColor(disabled)
   */
  neutral10: "var(--secondary)",

  /*
   * control/borderColor
   * option/color(disabled)
   * indicators/color
   * indicators(separator)/backgroundColor
   * indicators(loading)/color
   */
  // neutral20: 'var(--neutral-20)',

  /*
   * control/borderColor(focused)
   * control/borderColor:hover
   */
  neutral30: "var(--neutral-30)",

  /*
   * menu(notice)/color
   * singleValue/color(disabled)
   * indicators/color:hover
   */
  // neutral40: 'var(--neutral-40)',

  /*
   * placeholder/color
   */
  // neutral50: 'var(--neutral-50)',

  /*
   * indicators/color(focused)
   * indicators(loading)/color(focused)
   */
  // neutral60: 'var(--neutral-60)',

  // neutral70: 'var(--neutral-70)',

  /*
   * input/color
   * multiValue(label)/color
   * singleValue/color
   * indicators/color(focused)
   * indicators/color:hover(focused)
   */
  neutral80: "var(--neutral-80)",

  neutral90: "var(--neutral-90)",

  /*
   * control/boxShadow(focused)
   * control/borderColor(focused)
   * control/borderColor:hover(focused)
   * option/backgroundColor(selected)
   * option/backgroundColor:active(selected)
   */
  primary: "var(--primary)",

  /*
   * option/backgroundColor(focused)
   */
  primary25: "var(--primary)",

  /*
   * option/backgroundColor:active
   */
  // primary50: "var(--primary)",

  // primary75: "var(--primary)",
};

const RelatedSelect = ({ input, ...props }) => {
  const [defaultOptions, setDefaultOptions] = React.useState(null);

  const optionLabel = props.optionLabel || "name";
  const defaultGetOptionValue = (option) => option["id"];
  const getOptionValue = props.getOptionValue || defaultGetOptionValue;

  const onMenuOpen = () => {
    if (defaultOptions === null) {
      onSearch().then((options) => {
        setDefaultOptions(options);
      });
    }
  };

  const onSearch = async (inputValue) => {
    let params;

    if (props.params) {
      params = Object.assign({}, props.params);
    } else {
      params = {};
    }

    if (inputValue) {
      params.search = inputValue;
    }

    if (!params.fields) {
      params.fields = "id,name";
    }

    let response = await service.autocomplete(props.resource, params);

    return response.data.results;
  };

  // const onChange = (newValue) => {
  //   console.log(newValue)
  // };

  return (
    <AsyncSelect
      className="related-field"
      cacheOptions
      onMenuOpen={onMenuOpen}
      defaultOptions={defaultOptions}
      theme={(theme) => {
        // console.log(theme)
        return {
          ...theme,
          // borderRadius: 0,
          colors: {
            ...theme.colors,
            ...colors,
            // primary25: 'hotpink',
            // primary: 'black',
          },
        };
      }}
      loadOptions={onSearch}
      onChange={input.onChange}
      value={input.value}
      getOptionLabel={(option) => option[optionLabel]}
      getOptionValue={getOptionValue}
      {...props}
    />
  );
};

const RelatedField = ({ name, label, ...props }) => {
  const fieldId = `input-${name}`;

  return (
    <Field name={name}>
      {({ input, meta }) => (
        <BootstrapField label={label} fieldId={fieldId} meta={meta}>
          <RelatedSelect input={input} {...props} />
        </BootstrapField>
      )}
    </Field>
  );
};

export default RelatedField;
export { RelatedSelect };
