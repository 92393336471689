
import React from 'react'

import { FieldArray } from "react-final-form-arrays";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Form, Field } from 'react-final-form'
import CIcon from '@coreui/icons-react'
import BootstrapField from "admin-kit/components/fields/bootstrap"

import TextField from "admin-kit/components/fields/text"
import RelatedField from "admin-kit/components/fields/related"
import {Fields} from "../app-instance"

import ChannelDropContext from "./drop.js"
import DurationField from "../duration"
import ContentPreview from "../content-preview"
import { v4 as uuidv4 } from 'uuid';



const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle
});

const getListStyle = (isDraggingOver, isOver) => ({
  background: (isDraggingOver || isOver) ? "lightblue" : null,
  // opacity: isOver ? . : 1
});


const makeOnDragEndFunction = fields => result => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  fields.move(result.source.index, result.destination.index);
};



const FramesetList = ({name, label})=>{

  const [currentArea, setCurrentArea] = React.useState(null)


  if(currentArea){
    const{name, app} = currentArea

    return <div className="card">
      <div className="card-header d-flex align-items-center">
        Properties
        <div className="ml-auto">
          <button className="btn btn-outline-light rounded" onClick={()=>{setCurrentArea(null)}}><CIcon name="cil-x"/></button>
        </div>
      </div>
      <div className="card-body">


      {app && app.current_version &&
        <Fields label="App" name={`${name}.data`} fields={app.current_version.data.content}/>
      }
      <hr/>

      <RelatedField name={`${name}.zone`} label="zone" resource={"zones"} isClearable={true}/>
      <TextField name={`${name}.scale`} label="scale" choices={[{id: "fit", label: "Fit"}, {id:"fill", label:"Fill"}]} />

      <TextField name={`${name}.width`} label="width" type="number"/>
      <TextField name={`${name}.height`} label="height" type="number"/>
      <TextField name={`${name}.left`} label="left" type="number"/>
      <TextField name={`${name}.top`} label="top" type="number"/>
      </div>


    </div>
  }

  return <FieldArray name={name}>
  {({ fields, meta }) => (

    <BootstrapField label={label} meta={meta}>
    <div className="d-flex">
      <div className="text-muted">Drag content from sidebar or add a placeholder.</div>
    <button className="btn btn-outline-dark btn-sm ml-auto" type="button" onClick={()=>{

      fields.unshift({
        content_type: "placeholder",

        duration:10,
        scale: "fit",
        left: 0,
        top: 0,
        width: 1920/2,
        height: 1080/2,

        _uuid: uuidv4(), // until we get a db id we use this as key

      })

    }}><i className="fa fa-plus mr-1"/> Add placeholder area</button>
    </div>
    <ChannelDropContext fields={fields}>
      {({isOver, dropRef})=>(
        <div ref={dropRef}>

        <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
          <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
            ref={provided.innerRef}
            className={"inline-items"}
            style={getListStyle(snapshot.isDraggingOver, isOver)}
            >
            {fields.map((name, index) => (
              <Draggable
              key={name}
              draggableId={name}
              index={index}
              >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  className={`inline-item ${fields.value[index].delete ? "delete" : ""}`}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >

                <span {...provided.dragHandleProps} className="drag-handle"><CIcon name="cil-swap-vertical"/></span>

                <div className="inline-content">
                  <ContentPreview data={fields.value[index]} />
                </div>

                <div className="inline-tools">

                  { fields.value[index].zone &&
                    <>
                    <span className="mr-1 badge badge-primary">
                      {fields.value[index].zone.name}

                    </span>
                    </>
                  }


                  <span onClick={() => {
                    setCurrentArea({
                      name, app: fields.value[index].app
                    })
                  }} className="tool-btn">
                    <CIcon name="cil-options"/>
                  </span>
                  <span onClick={() => {
                    let data = fields.value[index]

                    if(! data.id){
                      // Item is not persisted in db yet
                      fields.remove(index)

                    }else{
                      let newData = Object.assign({}, data)
                      newData.delete = ! newData.delete
                      fields.update(index, newData)
                    }

                  }} className="tool-btn">
                    {fields.value[index].delete ? <CIcon name="cil-action-undo"/> : <CIcon name="cil-trash"/>}

                  </span>
                </div>


                </div>
              )}
              </Draggable>
            ))}

            {provided.placeholder}

            { !(fields.value && fields.value.length ) && <div className="empty-list lead">Drop content here</div>}
            </div>

          )}
          </Droppable>
        </DragDropContext>
        </div>
      )}
    </ChannelDropContext>
    </BootstrapField>
  )}
  </FieldArray>
}


export default FramesetList
