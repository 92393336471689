import React, { Component } from 'react';
import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';

import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import {RichtextField} from "./richtext"



const HtmlRichTextField = ({input})=>{

  // TODO: send contentState directly to editor without converting to raw

  let value;
  try{

    value = input.value ?
      convertToRaw(ContentState.createFromBlockArray(htmlToDraft(input.value))) :
      null

  }catch(e){
    console.log(e)
    value = null

  }

  const onChange = (newValue)=>{
    input.onChange(draftToHtml(newValue))

  }

  return <RichtextField input={{value, onChange}}/>
}


const WrappedRichTextField = ({name, label}) => {

  const fieldId = `input-${name}`

  return <Field name={name}>
    {({input, meta}) => (
      <BootstrapField label={label} fieldId={fieldId} meta={meta}>
        <HtmlRichTextField input={input}/>
      </BootstrapField>
    )}
  </Field>
}



export default WrappedRichTextField
