import React, { createContext, useContext } from 'react';


export const TranslationContext = createContext();

let config = {}

export const initTranslation = (translationResources, defaultLanguage)=>{
  config.translationResources = translationResources
  config.defaultLanguage = defaultLanguage
}

const allLanguages = {
  "sv": "Svenska",
  // "nb": "Norsk",
  "en": "English",
  "de": "German"
}

export const TranslationWrapper = ({children})=>{
  const [language, setLanguage] = React.useState(config.defaultLanguage)

  const t = React.useCallback((value, namespace)=>{
    namespace = namespace || "common"

    let translationResources = config.translationResources

    let translatedValue = translationResources[language]?.[namespace]?.[value]
    translatedValue = translatedValue || translationResources[language]?.["navigation"]?.[value]
    // translatedValue = translatedValue || translationResources["en"]?.[namespace]?.[value]
    translatedValue = translatedValue || value

    return translatedValue

  }, [language])

  return <TranslationContext.Provider value={{language, setLanguage, t, allLanguages}}>
    {children}
  </TranslationContext.Provider>
}

export function useTranslation() {
  return useContext(TranslationContext)
}
