import React from "react";

import {
  RelatedLink,
  DetailLink,
  Checkbox,
  DetailActions,
} from "admin-kit/components/cells";

import { ScreenStatusList, Thumbnail, AccountList, TagComponent, FolderComponent, ListEditableField, MediaStatus, TagList, FromNow } from "./components";

import AppInstanceDetail, {
  AppInstanceEdit,
} from "./resources/app-instance/detail";

import ThemeDetail, {
  ThemeEdit,
} from "./resources/theme/detail";

import CanvasCreate, {
  CanvasEdit,
} from "./resources/canvas/detail";

import ChannelCreate, {
  ChannelEdit,
} from "./resources/channel/detail";


const Dashboard = React.lazy(() => import("./resources/dashboard"));

const Billing = React.lazy(() => import("./resources/billing"));

const GenericList = React.lazy(() => import("admin-kit/resources/base/list"));
const GenericDetail = React.lazy(() =>
  import("admin-kit/resources/base/detail")
);
const SupportPage = React.lazy(() =>
  import("admin-kit/resources/support/index")
);
const SupportDetailPage = React.lazy(() =>
  import("admin-kit/resources/support/detail")
);

const MediaList = React.lazy(() => import("./resources/media/list"));
const MediaDetail = React.lazy(() => import("./resources/media/detail"));

const AppDetail = React.lazy(() => import("./resources/app/detail"));
const ScreenDetail = React.lazy(() => import("./resources/screen/detail"));
const CastDetail = React.lazy(() => import("./resources/cast/detail"));

const PlaylistDetail = React.lazy(() => import("./resources/playlist/detail"));
const ScreenSettingsDetail = React.lazy(() => import("./resources/screen-settings/detail"));


const APP_TABS = [
  { resource: "apps", label: "Apps" },
  { resource: "app-versions", label: "App verisons" },
];

const resources = [
  {
    name: "app-instances",
    label: "App signs",
    icon: "",

    list: {
      columns: [
        { name: "thumbnail", label: "Thumbnail", component: Thumbnail },
        { name: "name", label: "Name", component: DetailLink },
        { name: "app_label", label: "App" },
        { name: "type", label: "Type" },
      ],
      alwaysIncludeFields: ["type", ],
      filters: [],
    },

    detail: {
      createComponent: AppInstanceDetail,
      editComponent: AppInstanceEdit,
      actions: [{ label: "Generate thumbnail", name: "generate_thumbnail" }],
      drawer: "media",
      fields: [
        { name: "name", label: "Name", type: "text" },
        { name: "data", label: "Fields", type: "message" },
        { name: "theme", label: "Theme", type: "related", resource: "themes", params: {fields: "id,name,data"} },
      ],
    },
  },

  {
    name: "apps",
    label: "Apps",
    icon: "",

    list: {
      tabs: APP_TABS,

      columns: [{ name: "name", label: "Name", component: DetailLink }],
      filters: [],
    },

    detail: {
      fields: [
        { name: "name", label: "Name", type: "text" },
        { name: "description", label: "Description", type: "textarea" },
      ],
    },
  },

  {
    name: "app-versions",
    label: "App verisons",
    icon: "",

    list: {
      tabs: APP_TABS,
      columns: [{ name: "name", label: "Name", component: DetailLink }],
      filters: [],
    },

    detail: {
      createComponent: AppDetail,
      editComponent: AppDetail,
      drawer: "app_field",

      actions: [{ label: "Prepare for preview", name: "prepare" }],

      fields: [
        { name: "app", label: "App", type: "related", resource: "apps" },
        { name: "version_number", label: "version number", type: "text" },
        {
          name: "media",
          label: "Archive",
          type: "related",
          resource: "media",
          params: { type: "archive" },
        },
        { name: "data.content", label: "Fields", type: "template" },
      ],
    },
  },

  {
    name: "custom-apps",
    label: "Templates",
    icon: "",

    list: {
      columns: [{ name: "name", label: "Name", component: DetailLink }],
      filters: [],
    },

    detail: {
      createComponent: AppDetail,
      editComponent: AppDetail,
      drawer: "app_field",
      actions: [{ label: "Prepare", name: "prepare" }],
      fields: [
        { name: "name", label: "Name", type: "text" },
        {
          name: "media",
          label: "Archive",
          type: "related",
          resource: "media",
          params: { type: "archive" },
        },
        { name: "data.content", label: "Fields", type: "template" },
        { name: "theme_data.content", label: "Theme fields", type: "template" },
      ],
    },
  },

  {
    name: "screens",
    label: "Screens",
    icon: "",

    list: {
      actions: [{ label: "Publish", name: "publish_many" }],
      drawer: ["media", "link", "app_instance", "canvas", "playlist"],
      alwaysIncludeFields: ["heartbeat_at", "heartbeat_data", "published_at"],
      columns: [
        // {name: "id", label:"#"},
        { name: "name", label: "Name", component: DetailLink },
        { name: "heartbeat_at", label: "Status", component: ScreenStatusList },
        {
          name: "channel.name",
          label: "Channel",
          component: ListEditableField,
          resource: "channels",
          related_name: "channel",
        },
        {
          name: "screen_group.name",
          label: "Group",
          component: ListEditableField,
          resource: "screen-groups",
          related_name: "screen_group",
        },
      ],
      filters: [
        {
          name: "channel",
          label: "Channel",
          type: "related",
          resource: "channels",
        },
        {
          name: "screen_group",
          label: "Group",
          type: "related",
          resource: "screen-groups",
        },
      ],
    },

    detail: {
      drawer: ["media", "link", "app_instance", "canvas", "playlist"],
      editComponent: ScreenDetail,
      createComponent: ScreenDetail,
      actions: [{ name: "publish", label: "Publish" }],
      // {name:"unregister", label: "Unregister", confirm:true}
      layout: {
        Basic: {
          Basic: ["name", "activation_code"],
          Status: ["status"],
          Config: ["screen_group", "channel"],
          Content: ["scheduled_items"],
        },
        Information: {
          "": ["description"],
        },
        Advanced: {
          "": ["custom_fields"],
        },
      },
    },
  },

  {
    name: "channels",
    label: "Channels",
    icon: "",

    list: {
      columns: [
        // {name: "id", label:"#"},
        { name: "name", label: "Name", component: DetailLink },
      ],
      filters: [],
    },

    detail: {
      createComponent: ChannelCreate,
      editComponent: ChannelEdit,
      drawer: ["app", "media", "playlist"],

      fields: [
        { name: "name", label: "Name", type: "text" },
        { name: "areas", label: "Layout", type: "frameset" },
      ],
    },
  },

  {
    name: "media",
    label: "Media",
    icon: "",
    quick_filters: [
      { label: "All", filter: {} },
      { label: "Image", filter: { type: "image" } },
      { label: "Video", filter: { type: "video" } },
      { label: "Archive", filter: { type: "archive" } },
    ],

    disableCreate: true,

    list: {
      component: MediaList,
      columns: [
        // {name: "id", label:"#"},
        { name: "thumbnail", label: "Thumbnail", component: Thumbnail },
        { name: "name", label: "Name", component: DetailLink },
        { name: "status", label: "Status", component: MediaStatus },
        // { name: "folder.name", label: "Folder", component: FolderComponent},
        { name: "tags", label: "Tags", component: TagComponent},
      ],

      filters: [
        {
          name: "tags",
          label: "Tag",
          type: "related",
          resource: "tags",
        },
      ]
    },

    detail: {
      editComponent: MediaDetail,
      actions: [{ label: "Generate thumbnail", name: "generate_thumbnail" }],
      layout: {
        "": {
          "": ["preview", "name", "tags"],
        },
      },
    },
  },

  {
    name: "canvas",
    label: "Canvas",
    list: {
      columns: [
        { name: "thumbnail", label: "Thumbnail", component: Thumbnail },
        { name: "name", label: "Name", component: DetailLink },
        { name: "width", label: "Width" },
        { name: "height", label: "Height" },
      ],
    },
    detail: {
      actions: [{ label: "Generate thumbnail", name: "generate_thumbnail" }],
      drawer: "media",
      layout: {
        "": {
          "": ["name", "data"]
        }
      },
      createComponent: CanvasCreate,
      editComponent: CanvasEdit,
    },
  },

  {
    name: "screen-groups",
    label: "Screen groups",
    list: {
      columns: [{ name: "name", label: "Name", component: DetailLink }],
    },
    detail: {},
  },

  {
    name: "screen-settings",
    label: "Screen settings",
    list: {
      columns: [{ name: "name", label: "Name", component: DetailLink }],
    },
    detail: {
      createComponent: ScreenSettingsDetail,
      editComponent: ScreenSettingsDetail,
    },
  },

  {
    name: "screen-locations",
    label: "Screen locations",
    list: {
      columns: [{ name: "name", label: "Name", component: DetailLink }],
    },
    detail: {},
  },

  {
    name: "playlists",
    label: "Playlists",
    list: {

      columns: [
        { name: "thumbnail", label: "Thumbnail", component: Thumbnail },
        { name: "name", label: "Name", component: DetailLink }
      ],
    },
    detail: {
      editComponent: PlaylistDetail,
      createComponent: PlaylistDetail,
      drawer: ["media", "app_instance", "link", "canvas"],
      fieldOverrides: {
        // "items": {type: "playlist"},
      },
      layout: {
        "": {
          "": ["name", "items"],
        },
      },
      // {name: "playlist.items", label: "Playlist", type: "playlist"},
    },
  },

  {
    name: "zones",
    label: "Zones",
    list: {
      columns: [
        { name: "name", label: "Name", component: DetailLink },
        { name: "primary", label: "Primary", component: Checkbox }
      ],
    },
    detail: {},
  },

  {
    name: "links",
    label: "Links",
    list: {
      columns: [
        { name: "thumbnail", label: "Thumbnail", component: Thumbnail },
        { name: "name", label: "Name", component: DetailLink },
        { name: "url", label: "Url" },
      ],
    },
    detail: {
      actions: [{ label: "Generate thumbnail", name: "generate_thumbnail" }],
    },
  },

  {
    name: "tags",
    label: "Tags",
    list: {
      columns: [{ name: "name", label: "Name", component: DetailLink }],
    },
    detail: {

    },
  },
  {
    name: "media-folders",
    label: "Folders",
    list: {
      columns: [{ name: "name", label: "Name", component: DetailLink }],
    },
    detail: {

    },
  },
  {
    name: "themes",
    label: "Themes",
    list: {
      columns: [
        { name: "name", label: "Name", component: DetailLink },
        { name: "app_label", label: "App" },
        { name: "type", label: "Type" },
      ]
    },

    detail: {
      createComponent: ThemeDetail,
      editComponent: ThemeEdit,
      drawer: "media",
      fields: [
        { name: "name", label: "Name", type: "text" },
        { name: "data", label: "Fields", type: "message" },
      ],
    },

  },
  {
    name: "fonts",
    label: "Fonts",
    list: {
      columns: [{ name: "name", label: "Name", component: DetailLink }],
    },
    detail: {
      actions: [{ label: "Prepare", name: "prepare" }],
      fields: [
        { name: "name", label: "Name", type: "text" },
        { name: "default", label: "Default", type: "boolean", default: true },
        {
          name: "media",
          label: "Archive",
          type: "related",
          resource: "media",
          params: { type: "archive" },
        },
      ],

    },
  },
  {
    name: "users",
    label: "Users",
    list: {
      columns: [
        { name: "email", label: "Email", component: DetailLink },
        { name: "groups", label: "Groups", component: AccountList },
        { name: "accounts", label: "Accounts", component: AccountList },
        { name: "organization.name", label: "Organization" },
        { name: "last_login", label: "Last login", component: FromNow },
      ],
    },
    detail: {
      actions: [{ name: "su_login", label: "Login as user" }],
    },
  },
  {
    name: "accounts",
    label: "Account",
    list: {
      columns: [
        { name: "name", label: "Name", component: DetailLink },
        { name: "organization.name", label: "Organization"}
      ],
    },
    detail: {},
  },
  {
    name: "organizations",
    label: "Organization",
    list: {
      columns: [{ name: "name", label: "Name", component: DetailLink }],
    },
    detail: {},
  },

  {
    name: "casts",
    label: "Cast",
    list: {
      columns: [
        { name: "name", label: "Name", component: DetailLink },
        { name: "is_active", label: "Active", component: Checkbox }

      ],
    },
    detail: {
      actions: [{ name: "publish", label: "Publish" }],
      drawer: ["media", "link", "app_instance", "canvas", "playlist"],
      editComponent: CastDetail,
      createComponent: CastDetail,
      layout: {
        "": {
          "": ["name", "is_active"],
          Schedule: ["schedule"],
          Content: ["content"],
          Presentation: ["mode", "duration", "zone"],
          Screens: ["screen_groups"]
        },
      },
    },
  },
  {
    name: "emails",
    label: "Emails",
    list: {
      columns: [
        { name: "subject", label: "subject", component: DetailLink },
        { name: "recipients", label: "recipients", component: TagList},
        { name: "success", label: "success", component: Checkbox},
        { name: "date_sent", label: "date_sent", component: FromNow},
      ],
    },
    detail: {
    },
  },

  {
    name: "invitations",
    label: "Invitations",
    list: {
      columns: [
        { name: "email", label: "email"},
        { name: "account.name", label: "account"},
        { name: "created_at", label: "created_at"},
        { name: "accepted_at", label: "accepted_at"},
        { name: "rejected_at", label: "rejected_at"},
      ],
    },
    detail: {
      actions: [{ label: "Send email", name: "send_email" }],
    },
  },

];
const getRoutes = (t)=>{
const routes = [
  { path: "/", exact: true, name: t("Home") },
  { path: "/dashboard", name: t("Dashboard"), component: Dashboard },
  { path: "/billing", name: t("Billing"), component: Billing },
];

for (let resource of resources) {
  routes.push({
    path: `/${resource.name}`,
    exact: true,
    resource: resource,
    name: t(resource.label),
    component: resource.list.component || GenericList,
  });
  routes.push({
    path: `/${resource.name}/create`,
    exact: true,
    resource: resource,
    name: t("Create"),
    component: resource.detail.createComponent || GenericDetail,
  });
  routes.push({
    path: `/${resource.name}/:id`,
    exact: true,
    resource: resource,
    name: t(resource.label),
    component: resource.detail.editComponent || GenericDetail,
  });
}

routes.push({
  path: `/support`,
  exact: true,
  name: t("Support"),
  component: SupportPage,
});
routes.push({
  path: `/support/:id`,
  exact: true,
  name: t("Support"),
  component: SupportDetailPage,
});

return routes
}
export default getRoutes;
