import React, { Component } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { BlockPicker, TwitterPicker } from 'react-color';

// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';


/*
function uploadImageCallBack(file) {
  return new Promise(
    (resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://api.imgur.com/3/image');
      xhr.setRequestHeader('Authorization', 'Client-ID XXXXX');
      const data = new FormData();
      data.append('image', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    }
  );
}
*/




class ColorPic extends Component {

  stopPropagation = (event) => {
    event.stopPropagation();
  };

  onChange = (color) => {
    const { onChange } = this.props;
    onChange('color', color.hex);
  }

  renderModal = () => {
    const { color } = this.props.currentState;


    return (
      <div
      style={{
        position: "absolute",
        top: 20,
        left: -65,
        zIndex: 99,
      }}
        onClick={this.stopPropagation}
      >
        <BlockPicker color={color || ""}
          triangle={"hide"}
          colors={['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#fe9549', '#ba68c8', '#ffffff']}
          onChangeComplete={this.onChange}
          />
      </div>
    );
  };

  render() {
    const { expanded, onExpandEvent } = this.props;

    return (
      <div className="rdw-block-wrapper" style={{
        position: "relative"
      }}>
        <div onClick={onExpandEvent} className="rdw-option-wrapper">
          <i className="fa fa-palette" style={{color: "#000000"}}/>
        </div>
        {expanded ? this.renderModal() : undefined}
      </div>
    );
  }
}



class RichtextField extends Component {

    constructor(props) {
      super(props);

      this.state = {
      }

      if(props.input.value && props.input.value.blocks){
        this.state.contentState = props.input.value;
      }

      this.onContentStateChange = this.onContentStateChange.bind(this);
  }

  onContentStateChange(contentState){

    // draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // console.log(contentState)
    this.props.input.onChange(contentState)

  };

  render() {
    const { editorState } = this.state;
    return (
      <div>
        <Editor


          wrapperClassName="richtext-wrapper"
          editorClassName="richtext-editor"
          onContentStateChange={this.onContentStateChange}
          defaultContentState={this.state.contentState}
          stripPastedStyles={true}
          toolbar={{

            options:['blockType', 'inline',  'list', 'textAlign',
              // 'link', 'image',
              'colorPicker',
              'remove', 'history'],
            // 'fontSize', 'colorPicker', 'fontFamily',  'embedded', 'emoji',
            inline: {
              // inDropdown: true
              options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
            },
            colorPicker: { component: ColorPic },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            // history: { inDropdown: true },
            // image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
          }}

        />
        {

        // <textarea
        //   disabled
        //   value={JSON.stringify(this.props.input.value, null, 4)}
        //
        // />
        }
      </div>
    );
  }
}




const WrappedRichTextField = ({name, label}) => {

  const fieldId = `input-${name}`

  return <Field name={name}>
    {({input, meta}) => (
      <BootstrapField label={label} fieldId={fieldId} meta={meta}>
        <RichtextField input={input}/>
      </BootstrapField>
    )}
  </Field>
}



export default WrappedRichTextField

export {RichtextField}
