import React from 'react'

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"


const format = (value)=>{
  return value ? new Date(value) : null
}

const parse = (value)=>{
  return value ? value.toISOString().substring(0, 10): null
}


const DateField = ({name, label, initialValue}) => {

  const fieldId = `input-${name}`

  return <Field name={name} initialValue={initialValue} format={format} parse={parse}>
    {({input, meta}) => (
      <BootstrapField label={label} fieldId={fieldId} meta={meta}>
        <div>
        <DatePicker
          className="form-control"
          // showTimeSelect={true}
          dateFormat="yyyy-MM-dd" // HH:mm
          // timeFormat="HH:mm"
          selected={input.value}
          onChange={input.onChange}
       />
       </div>
      </BootstrapField>
    )}
  </Field>
}

export default DateField
