import React from 'react'

import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"


// https://github.com/final-form/react-final-form/issues/130
// empty string is not sent
const identity = value => (value);



const NumberField = ({name, label, placeholder, type, extraClassName, initialValue, required, min, max, step}) => {

  const fieldId = `input-${name}`

  let className = `form-control ${extraClassName || ""}`
  return <Field name={name} initialValue={initialValue} parse={identity}>
    {({input, meta}) => (
      <BootstrapField label={label} fieldId={fieldId} meta={meta}>
        <input {...input} type={type || "number"} className={className} id={fieldId} placeholder={placeholder} required={required} step={step} min={min} max={max} />
      </BootstrapField>
    )}
  </Field>
}

export default NumberField
