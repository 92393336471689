

import React, {useState} from 'react'

import { FieldArray } from "react-final-form-arrays";

import { Form, Field } from 'react-final-form'
import {
  CModal,
  CModalFooter,
  CModalHeader,
  CButton,
  CModalBody,
} from "@coreui/react"


import TextField from "./text"
import TextAreaField from "./textarea"
import CheckboxField from "./checkbox"
import TagsField from "./tags"

import CIcon from '@coreui/icons-react'
import BootstrapField from "./bootstrap"


import service from "../../service"

import { useDrop, useDrag } from 'react-dnd'
import { v4 as uuidv4 } from 'uuid';






const getListStyle = (isOver) => ({
  background: (isOver) ? "lightblue" : null,
  // opacity: isOver ? . : 1
});


const makeOnDragEndFunction = fields => result => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  fields.move(result.source.index, result.destination.index);
};



const Droppable = ({children, fields, forceUpdate})=> {


  const [{ isOver }, drop] = useDrop({
    accept: ["template_field_factory", "template_field"],

    drop: async (item, monitor) => {
      const didDrop = monitor.didDrop()

      if(didDrop){
        return
      }

      if(item.type === "template_field"){

        if(fields.name === item.fields.name){
          return
        }

        let newField = Object.assign({}, item.field)
        // newField._uuid = uuidv4()
        fields.push(newField)
        item.fields.remove(item.index)

      }else{

        let data = {
          // _uuid: uuidv4(),
          type: item.field.type,
          attributes: {}
        }
        if(item.field.children){
          data.children = []
        }
        fields.push(data)

      }
      forceUpdate()

    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })

  return children({isOver, dropRef:drop})

}


const Draggable = ({field, children, fields, index, name, forceUpdate}) => {

  // const originalIndex = findCard(id).index

  const [{ isDragging }, drag, preview] = useDrag({

    item: { type: "template_field", field },
    begin: (monitor) => {

      return { type: "template_field", field, fields, index}



    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),

    // end: (dropResult, monitor) => {
    //   const item = monitor.getItem()
    //   const didDrop = monitor.didDrop()
    //
    //   if (!didDrop) {
    //     // moveCard(droppedId, originalIndex)
    //   }
    //
    // },
  })


  const [{isOver}, drop] = useDrop({
    accept: "template_field",
    // canDrop: () => false,
    drop: (item, monitor)=>{
      const didDrop = monitor.didDrop()

      if(didDrop){
        return
      }


      if(item.fields.name != fields.name){

        let newField = Object.assign({}, item.field)
        // newField._uuid = uuidv4()

        // fields.insert(index, newField)
        fields.push(newField)
        item.fields.remove(item.index)

      }else{

        fields.move(item.index, index)
      }

      forceUpdate()

    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
    //
    // hover(item) {
    //
    //   if (item.field._uuid !== field._uuid) {
    //     // const { index: overIndex } = findCard(id)
    //     // moveCard(draggedId, overIndex)
    //     fields.move(item.index, index)
    //   }
    //
    // },

  })

  return children({isDragging, drop, drag, preview, isOver})

}




const AttributesModal = ({modal, setModal}) => {

  const close = ()=>setModal(false)

  const onSubmit = (values) => {
    modal.cb(values)
    setModal(null)
  }

  let field, fieldBlock;

  if(modal){
    field = modal.field
    fieldBlock = modal.fieldBlock
  }


  return <CModal
    show={!!modal}
    onClose={close}
  >

  <Form
    onSubmit={onSubmit}
    initialValues={field}
    render={({ handleSubmit, submitError, submitting }) => (

      <>

      <CModalHeader
        // closeButton //hmm, triggers form save on detail form...
      >{fieldBlock ? fieldBlock.type : "-"}</CModalHeader>
      <CModalBody>
        {
          fieldBlock && Object.keys(fieldBlock.attributes).map((name, index)=>{

            const attrType = fieldBlock.attributes[name].type
            const fieldName = `attributes.${name}`

            if(attrType === "string"){
              return <TextField key={name} name={fieldName} label={name}/>

            }else if(attrType === "number"){
              return <TextField key={name} name={fieldName} label={name} type={"number"}/>

            }else if(attrType === "boolean"){
              return <CheckboxField key={name} name={fieldName} label={name}/>

            }else if(attrType === "text"){
              return <TextAreaField key={name} name={fieldName} label={name}/>

            }else if(attrType === "tags"){
              return <TagsField key={name} name={fieldName} label={name}/>

            }else {
              return <div key={name}><span className="badge">{name} - {attrType}</span></div>
            }

          })
        }

      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={close}
        >Cancel</CButton>
        <CButton color="primary" onClick={handleSubmit}>Save</CButton>{' '}
      </CModalFooter>
      </>
    )}
    />
  </CModal>
}







const TemplateField = ({fields, index, name, forceUpdate, setModal, blockConfig}) => {
  const field = fields.value[index]
  const fieldBlock = blockConfig.blocks[field.type]



  return <Draggable field={field} fields={fields} index={index} name={name} forceUpdate={forceUpdate}>

    {({isDragging, isOver, drop, drag, preview})=>(
    <div className={`inline-item`} style={{
        backgroundColor: fieldBlock.children ? null : fieldBlock.color,
        borderColor: fieldBlock.color,
        opacity: isDragging ? .5 : 1,
        // background: isOver ? "blue" : null
      }} ref={(node)=>preview(drop(node))}>
      <span className="drag-handle" style={{backgroundColor: fieldBlock.color}} ref={drag}><CIcon size="sm" name="cil-swap-vertical"/></span>
      <div className="inline-content">

        {!fieldBlock ?
          <div className="badge badge-dark">missing type {field.type}</div> :
          (fieldBlock.children ?
          <TemplateContainer name={`${name}.children`} setModal={setModal} forceUpdate={forceUpdate} blockConfig={blockConfig}/> :
          null
        )

        }
        {

          // <div className="form-inline">
          //   <label>id</label>
          //   <Field name={`${name}.attributes.id`} className="form-control mx-1" component="input" placeholder="id"/>
          //   <label>name</label>
          //   <Field name={`${name}.attributes.name`} className="form-control mx-1" component="input" placeholder="name"/>
          // </div>
        }

      </div>

      <div className="inline-tools">

        {fieldBlock &&
          <span onClick={() => {

            let fieldCopy = JSON.parse(JSON.stringify(field))
            fields.insert(index+1, fieldCopy)
            forceUpdate()
          }} className="tool-btn">
            <CIcon name="cil-clone" size="sm"/>
          </span>
        }


        {fieldBlock &&
          <span onClick={() => {

            const cb = (newData)=>{
                fields.update(index, newData)
            }
            let fieldCopy = JSON.parse(JSON.stringify(field))
            setModal({field:fieldCopy, fieldBlock, cb})

          }} className="tool-btn">
            <CIcon name="cil-options" size="sm"/>
          </span>
        }

        <span onClick={() => {

          fields.remove(index)
          forceUpdate()
          }} className="tool-btn">
          <CIcon name="cil-trash" size="sm"/>
        </span>


        <div>
          <span className="badge badge-secondary mx-1">{field.type}</span>
          {
            fieldBlock.preview && <span className="badge badge-primary mx-1">{field.attributes[fieldBlock.preview]}</span>
          }

        </div>



      </div>

    </div>
    )}</Draggable>

}





const TemplateContainer = ({name, label, forceUpdate, setModal, blockConfig})=> {

  //
  // List of blocks
  //




  return <FieldArray name={name}>
  {({ fields, meta }) => (

      <Droppable fields={fields} forceUpdate={forceUpdate}>
      {({isOver, dropRef})=>(

        <div className={"inline-items compact"} style={getListStyle(isOver)} ref={dropRef}>
          {fields.map((name, index) => (

             // key={fields.value[index]._uuid}

            <TemplateField key={index} fields={fields} index={index} name={name} forceUpdate={forceUpdate} setModal={setModal} blockConfig={blockConfig}/>

          ))}


        </div>


      )}
    </Droppable>



  )}
  </FieldArray>
}




// const TreeWidget = ({resource})=>{
//
// }



const TemplateEditor = ({name, label, resource})=>{

  //     <BootstrapField label={label} meta={meta}>
  // </BootstrapField>

  const [version, setVersion] = useState(1)
  const [modal, setModal] = useState(null)

  const [blockConfig, setBlockConfig] = useState(null)

// blockbuilder_config

  React.useEffect(() => {
    const fetchConfig = async (id) => {
      let {styles, blocks} = await service.blockbuilder_config(resource.name)


      let blocksByType = {}

      for(let block of blocks.blocks){
        blocksByType[block.type] = block
      }
      setBlockConfig({
        "styles": styles,
        "blocks": blocksByType
      })
    }
    fetchConfig()
  }, [])



  const forceUpdate = ()=>{
    // Force update on reordering to prevent buggy nesting..
    setVersion(version +1)
  }


  return <>
    <div>
      <label>{label}</label>
      {blockConfig ?
        <div className="template-fields">
          <TemplateContainer name={`${name}.content`} setModal={setModal} label={label} key={version} forceUpdate={forceUpdate} blockConfig={blockConfig}/>
        </div>
        : <span className="spinner spinner-border spinner-sm"/>
      }
    </div>
    <AttributesModal modal={modal} setModal={setModal}/>
  </>
}


export default TemplateEditor
