
import React from "react"
import * as moment from 'moment'
import CIcon from '@coreui/icons-react'
import service from "admin-kit/service"
import RelatedFilter from "admin-kit/components/filters/related"
import {RelatedLink} from "admin-kit/components/cells"

import { useSelector, useDispatch } from 'react-redux'
import { parse, format } from 'date-fns'


const ContentThumbnail = ({thumbnail, className})=>{

  if(thumbnail && !Array.isArray(thumbnail)){
    thumbnail = [thumbnail]
  }

  return <div className={`media-thumbnail ${ thumbnail ? "d-block" : ""} ${className ? className : ""}`} style={{position: "relative"}}>
    {thumbnail ?
      thumbnail.map((thumbnail, index)=><div key={index} style={{position: "absolute", top: index*5, left: index*5, backgroundImage: `url("${thumbnail}")`}}/>) :
      <CIcon name="cil-image"/>
    }
  </div>
}

const Thumbnail = ({resource, value, row})=>{


  return <ContentThumbnail thumbnail={value}/>
}




const ScreenStatusList = ({resource, value, row})=>{
  let color;
  let label;

  if(row.heartbeat_at){
    let heartbeat_at = moment(row.heartbeat_at)
    let diff = moment().diff(heartbeat_at, 'minutes')

    if(diff < 10){
      color = "success"

    } else if(diff < 60){
      color = "warning"

    }else{
      color = "danger"
    }
    label = heartbeat_at.fromNow()

  }else{
    color = "danger"
    label = "never seen"
  }

  return <div className="d-flex align-items-center">
    <div className={`status-dot bg-${color}`}></div>
    <span>{label}</span>
  </div>


}

const AccountList = ({ resource, value, row }) => {
  return value ? value.map((account) => account.name).join(", ") : "-";
};


const TagComponent  = ({ resource, value, row }) => {
  return value ? value.map((tag, index)=>{

    const style = {
      background: tag.color
    }
    return <span key={index} className="badge badge-dark mr-1" style={style}>{tag.name}</span>
  }) : ""
}
const FolderComponent  = ({ resource, value, row }) => {

  return value ?  <span className="badge badge-primary">{value}</span> : ""
}





const ListEditableField = ({resource, value, row, column, triggerReload})=>{
  const [edit, setEdit] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const initialValue = row[column.related_name]

  const [newValue, onChange] = React.useState(initialValue)
  const dispatch = useDispatch()

  const save = async ()=>{

    let data = {[column.related_name]: newValue}
    setLoading(true)
    try{
      await service.partial(resource.name, row.id, data)
      dispatch({type: 'ADD_TOAST', toast: {"message": "Success", "type": "success"} })

    }catch(er){

      dispatch({type: 'ADD_TOAST', toast: {"message": er.toString(), "type": "danger"} })

    }

    setLoading(false)
    triggerReload()
    setEdit(false)
  }

  if(edit){
    return <div className="d-flex">
      <RelatedFilter value={newValue} onChange={onChange} filterConfig={column}/>
      <button className="btn btn-sm btn-success ml-2" onClick={()=>{save()}}>Save</button>
      <button className="btn btn-sm btn-dark ml-2" onClick={()=>{
        setEdit(false)
        onChange(initialValue)
      }}>Cancel</button>
    </div>
  }

  if(loading){
    return <span className="spinner-border spinner-border-sm"/>
  }

  return <div>

    <RelatedLink resource={resource} value={value} row={row} column={column}/>

    <button className="btn btn-sm"><i onClick={()=>{setEdit(true)}} className="fa fa-pen"/></button>
  </div>


}



const STATUS_UPLOADING = "uploading"
const STATUS_PROCESSING = "processing"
const STATUS_TRANSCODING = "transcoding"
const STATUS_TRANSCODING_ERROR = "transcoding_error"
const STATUS_OK = "ok"
const STATUS_FAIL = "fail"


const MediaStatus = ({resource, value, row})=>{

  const STATUS_COLOR = {
    [STATUS_OK]: "success",

    [STATUS_FAIL]: "danger",
    [STATUS_TRANSCODING_ERROR]: "danger",

    [STATUS_UPLOADING]: "primary",
    [STATUS_TRANSCODING]: "primary",
    [STATUS_PROCESSING]: "primary",
  }

  const STATUS_ICON = {
    [STATUS_OK]: "check-square",

    [STATUS_FAIL]: "exclamation-triangle",
    [STATUS_TRANSCODING_ERROR]: "exclamation-triangle",

    [STATUS_UPLOADING]: "cloud-upload-alt",
    [STATUS_TRANSCODING]: "sync",
    [STATUS_PROCESSING]: "sync",

  }

  let color = STATUS_COLOR[value];
  let icon = STATUS_ICON[value];

  return <div className={`text-${color}`}>
    <i className={`fa fa-${icon}`}/>
  </div>


}

const TagList = ({resource, value, row})=>{
  return value.join(", ")
}


const FromNow = ({resource, value, row})=>{
  if(value){
    return moment(value).fromNow()
  }
  return "-"
}


export {
  ScreenStatusList,
  Thumbnail,
  AccountList,
  TagComponent,
  FolderComponent,
  ContentThumbnail,
  ListEditableField,
  MediaStatus,
  TagList,
  FromNow
}
