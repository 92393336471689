
import React from "react";

import FramesetScreen from "./screen"
import FramesetList from "./list"

const Frameset = ({width, height})=>{


  return <div className="row">
    <div className="col-12 col-md-6">
      <FramesetList name="areas" label="Areas"/>
    </div>
    <div className="col-12 col-md-6">
      <FramesetScreen name="areas" width={width} height={height}/>
    </div>
  </div>

}

export default Frameset
