

import React from 'react'

import { FieldArray } from "react-final-form-arrays";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Form, Field } from 'react-final-form'
import CIcon from '@coreui/icons-react'
import BootstrapField from "./bootstrap"
import Fields from "./index"

import { useDrop } from 'react-dnd'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle
});

const getListStyle = (isDraggingOver, isOver) => ({
  background: (isDraggingOver || isOver) ? "lightblue" : null,
  // opacity: isOver ? . : 1
});


const makeOnDragEndFunction = fields => result => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  fields.move(result.source.index, result.destination.index);
};






const InlineField = ({name, label, fields})=>{
  let subFields = Object.values(fields).filter((field)=>field.name !== "id")

  return <FieldArray name={name}>
  {({ fields, meta }) => (

    <BootstrapField label={label} meta={meta} ignoreErrors={true}>
      <>
      <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
        <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
          ref={provided.innerRef}
          className={"inline-items inline-field"}
          style={getListStyle(snapshot.isDraggingOver)}
          >
          {fields.map((name, index) => (
            <Draggable
            key={name}
            draggableId={name}
            index={index}
            >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                className={`inline-item ${fields.value[index].delete ? "delete" : ""}`}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >

              <span {...provided.dragHandleProps} className="drag-handle"><CIcon name="cil-swap-vertical"/></span>

              <div className="inline-content">
                <Fields prefix={name} fields={subFields} />
              </div>

              <div className="inline-tools">

                <span onClick={() => {
                  let data = fields.value[index]

                  if(! data.id){
                    // Item is not persisted in db yet
                    fields.remove(index)

                  }else{
                    let newData = Object.assign({}, data)
                    newData.delete = ! newData.delete
                    fields.update(index, newData)
                  }

                }} className="tool-btn">
                  {fields.value[index].delete ? <CIcon name="cil-action-undo"/> : <CIcon name="cil-trash"/>}

                </span>
              </div>


              </div>
            )}
            </Draggable>
          ))}

          {provided.placeholder}

          </div>

        )}
        </Droppable>
        </DragDropContext>

        <button
          type="button"
          onClick={() => {
            fields.push({ })
          }} className="btn btn-link">

          <CIcon name="cil-playlist-add" className="mr-1"/>

          Add new element in {label}
        </button>
        </>


    </BootstrapField>
  )}
  </FieldArray>
}


export default InlineField
