import React from 'react'

import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"



const RangeField = ({name, label, placeholder, type, extraClassName, initialValue, required, min, max, percent}) => {

  const fieldId = `input-${name}`

  let className = `form-control-range ${extraClassName || ""}`
  return <Field name={name} initialValue={initialValue} parse={(value)=>{

        if(percent){
          return parseInt(value)/100.0
        }
        return parseFloat(value)

    }} format={(value)=>{

      if(value === undefined){
        value = 1
      }

      if(percent){
        return parseInt(parseFloat(value)*100.0)
      }
      return parseFloat(value)

    }}>
    {({input, meta}) => (
      <BootstrapField label={label} fieldId={fieldId} meta={meta}>
        <div className="d-flex">
          <input {...input} type={"range"} min={min || 0} max={max || 100} step={1} className={className} required={required} />
          {

            // <input {...input} type={"number"} className={className} id={fieldId} placeholder={placeholder} required={required} />
          }
        </div>
      </BootstrapField>
    )}
  </Field>
}

export default RangeField
