import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from 'admin-kit/assets/icons'
import app_icons from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'

import {initTranslation, TranslationWrapper} from "admin-kit/context/trans"



import localeEN from "./locales/en.json"
import localeSV from "./locales/sv.json"
// import localeNB from "./locales/nb.json"
import localeDE from "./locales/de.json"

const translationResources = {
  en: localeEN,
  sv:  localeSV,
  // nb:  localeNB,
  de:  localeDE,
};

let defaultLanguage = "en"
try{
  let storageLanguage = localStorage.getItem("signage:dashboard:language")
  if(storageLanguage && translationResources[storageLanguage]){
    defaultLanguage = storageLanguage
  }
}catch(er){
  console.log(er)
}
initTranslation(translationResources, defaultLanguage)


React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <TranslationWrapper>
      <App/>
    </TranslationWrapper>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
