import React from 'react'

import {Field} from 'react-final-form'

import TextField from "./text"
import SlugField from "./slug"
import TextAreaField from "./textarea"
import CheckboxField from "./checkbox"
import RadioField from "./radio"
import RatioField from "./ratio"
import ColorField from "./color"

import Condition from "./condition"

import RelatedField from "./related"
import InlineField from "./inline"

import DateField from "./date"
import DateTimeField from "./datetime"

import MediaField from "./media"

import HtmlField from "./html"
import RichTextField from "./richtext"

import BlockBuilderField from "./blockbuilder"



const renderField = ({field, prefix, resource}) => {

  if(field.condition){

    return <Condition key={field.name} when={field.condition.name} is={field.condition.value} compare={field.condition.compare}>
      {renderFieldInner({field, prefix, resource})}
    </Condition>
  }

  return renderFieldInner({field, prefix, resource})
}


const renderFieldInner = ({field, prefix, resource}) => {

  let name = field.name

  if(prefix){
    name = `${prefix}.${name}`
  }

  if(field.type === "text"){
    return <TextField name={name} label={field.label} key={field.name} choices={field.choices} initialValue={field.default} extraClassName={`${field.name === "name" ? "form-control-lg" : undefined}`}/>

  }else if(field.type === "integer"){
    return <TextField name={name} label={field.label} key={field.name} choices={field.choices} initialValue={field.default} type="number"/>

  }else if(field.type === "slug"){
    return <SlugField name={name} label={field.label} key={field.name} initialValue={field.default} />

  }else if(field.type === "decimal"){
    return <TextField name={name} label={field.label} key={field.name} choices={field.choices} initialValue={field.default} type="number"/>

  }else if(field.type === "boolean"){
    return <CheckboxField name={name} label={field.label} key={field.name} initialValue={field.default}/>

  }else if(field.type === "radio"){
    return <RadioField name={name} label={field.label} key={field.name} initialValue={field.default} choices={field.choices} type="radio" helpText={field.helpText}/>

  }else if(field.type === "ratio"){
    return <RatioField name={name} label={field.label} key={field.name} initialValue={field.default} />

  }else if(field.type === "textarea"){
    return <TextAreaField name={name} label={field.label} key={field.name}/>

  }else if(field.type === "color"){

    return <ColorField name={name} label={field.label} key={field.name} mode="hex"/>

  }else if(field.type === "html"){
    return <HtmlField name={name} label={field.label} key={field.name}/>

  }else if(field.type === "richtext"){
    return <RichTextField name={name} label={field.label} key={field.name}/>

  }else if(field.type === "date"){
    return <DateField name={name} label={field.label} key={field.name}/>

  }else if(field.type === "datetime"){
    return <DateTimeField name={name} label={field.label} key={field.name}/>


  }else if(field.type === "related"){
    return <RelatedField name={name} label={field.label} resource={field.resource} key={field.name} params={field.params} isMulti={field.multi} isClearable={true}/>

  }else if(field.type === "blockbuilder"){
    return <BlockBuilderField name={name} label={field.label} key={field.name} resource={resource}/>

  }else if(field.type === "media"){
    return <MediaField name={name} label={field.label} key={field.name} />


  }else if(field.type === "inline"){
    return <InlineField name={name} label={field.label} key={field.name} fields={field.fields}/>

  }else{
    return <Field key={field.name} name={field.name}>
      {({input})=>(
        <div className="mb-3">
          <label>{field.name}</label>
          <div>{String(input.value) || "-"}</div>
        </div>
      )}
    </Field>
  }
}



const Fields = ({fields, prefix, resource, FieldWrapper, customRenderField}) => {

  return <>
    {fields.map((field, index)=>{
      if(FieldWrapper){
        return <FieldWrapper field={field} prefix={prefix} resource={resource} renderField={renderFieldInner} key={index}/>
      }

      if(customRenderField){
        return customRenderField({field, prefix, resource, renderField})
      }

      return renderField({field, prefix, resource})

    })}
  </>
}
export default Fields
