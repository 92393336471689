import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet'

import React, { createRef } from 'react';

import {Field } from 'react-final-form'

import 'leaflet/dist/leaflet.css';
import L from 'leaflet'



export const pointerIcon = new L.Icon({
  iconUrl: 'https://d3970lb2lcqkxb.cloudfront.net/static/admin/content/images/marker-icon.png',
  iconAnchor: [12, 41],
  popupAnchor: [10, -44],
  iconSize: [25, 41],
  shadowUrl: 'https://d3970lb2lcqkxb.cloudfront.net/static/admin/content/images/marker-shadow.png',
  shadowSize: [41, 41],
  shadowAnchor: [15, 41],
})



const LocationMarker = (props)=>{

  const map = useMapEvents({
    click(e) {
      if(props.value){
        return
      }

      props.onChange({
        "lat": e.latlng.lat,
        "lng": e.latlng.lng
      })

    },
  })

  const markerRef = React.useRef()

  const dragend = ()=>{

    const marker = markerRef.current
    if (marker != null) {

      const latlng = marker.getLatLng()
      console.log(latlng)

      props.onChange({
        "lat": latlng.lat,
        "lng": latlng.lng
      })

    }


  }





  if(props.value){
    return <Marker
      icon={pointerIcon}
      draggable={true}
      eventHandlers={{dragend}}
      position={props.value}
      ref={markerRef}>
    </Marker>
  }

  return null

}


const LocationComponent = (props)=>{

  // const [zoom, setZoom] = React.useState(3);
  //
  // const [center, setCenter] = React.useState({
  //   lat: 51.505,
  //   lng: -0.09,
  // });

  let zoom = 3
  let center = [45,0]


  if(props.value){
    center = [props.value.lat, props.value.lng]
    zoom = 5
  }


  return (
    <MapContainer center={center} zoom={zoom} >

      <TileLayer
        url= 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
        // attribution= 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
      />
      <LocationMarker {...props}/>
    </MapContainer>
  )


}


const LocationField = ({name})=>{

  return <Field name={name}>
    {({input})=>(
      <LocationComponent {...input}/>
    )}
  </Field>
}




export default LocationField;
