
import React from "react"

import { Form, Field, FormSpy } from 'react-final-form'

import { Stage, Layer, Rect, Transformer, Circle, Group, Image, Text, Line, RegularPolygon } from 'react-konva';

import ColorField from "admin-kit/components/fields/color"
import RangeField from "admin-kit/components/fields/range"
import TextArea from "admin-kit/components/fields/textarea"
import MediaField from "admin-kit/components/fields/media"
import TextField from "admin-kit/components/fields/text"


import { ReactComponent as AlignLeftIcon } from '../../assets/editor/align-left.svg';
import { ReactComponent as AlignTopIcon } from '../../assets/editor/align-top.svg';
import { ReactComponent as AlignBottomIcon } from '../../assets/editor/align-bottom.svg';
import { ReactComponent as AlignRightIcon } from '../../assets/editor/align-right.svg';
import { ReactComponent as AlignVCenterIcon } from '../../assets/editor/align-v-center.svg';
import { ReactComponent as AlignHCenterIcon } from '../../assets/editor/align-h-center.svg';

import { useSelector, useDispatch } from 'react-redux'

import { v4 as uuidv4 } from 'uuid';


import { useDrop } from 'react-dnd'

import service from "admin-kit/service"



const ImageWrapper = React.forwardRef(({type, url, image, crop, ...props}, ref)=>{

  return <Group ref={ref} {...props} clipFunc={(ctx)=> {

      let radius = Math.min(props.height, props.width)/2
      let x = props.width/2
      let y = props.height/2

      if(crop === "circle"){
        ctx.arc(x, y, radius, 0, Math.PI * 2, false);
      }else if (crop === "rect"){
        ctx.rect(x-radius, y-radius, radius*2, radius*2);
      }else {
        ctx.rect(0, 0, props.width, props.height);

      }


    }}>
      {image &&
        <Image width={props.width} height={props.height} image={image}></Image>
      }
  </Group>
})


let TYPE_TO_SHAPE_COMPONENT = {
  "rect": Rect,
  "text": Text,
  "circle": Circle,
  "image": ImageWrapper,
  "line": Line,
  "triangle": RegularPolygon,
}



const CanvasDropContext = ({children, addShape})=> {

  const [{ isOver }, drop] = useDrop({
    accept: ["media"],

    drop: async (item, monitor) => {

      if(item.media.type === "image"){
        addShape({
          type: "image",
          url: item.media.url,
          width: item.media.width,
          height: item.media.height,
          media: item.media,
        })
      }


      // fields.unshift({
      //   content_type: TYPE_TO_CONTENT_TYPE[item.type],
      //
      //   duration:10,
      //   scale: "fit",
      //   left: 0,
      //   top: 0,
      //   width: 1920/2,
      //   height: 1080/2,
      //
      //   _uuid: uuidv4(), // until we get a db id we use this as key
      //
      //   media: item.media,
      //   app: item.app,
      //
      // })


    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })

  return children({isOver, dropRef:drop})

}





const Shape = ({type, shapeProps, isSelected, onSelect, onChange }) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  const [image, setImage] = React.useState()

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);


  React.useEffect(()=>{
    if(shapeProps.type === "image"){

      let image = new window.Image();
      image.src = shapeProps.url;
      
      const handleLoad = ()=>{
        setImage(image)

      }

      image.addEventListener('load', handleLoad);


      return ()=>{
        image.removeEventListener('load', handleLoad);
      }

    }

  }, [shapeProps])


  const ShapeComponent = TYPE_TO_SHAPE_COMPONENT[type]

  if(! ShapeComponent){
    return null
  }


  return (
    <React.Fragment>

      <ShapeComponent
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        image={image}

        draggable

        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}

        onTransform = {(e)=>{

          if(type === "text" || type === "line"){

            // prevent preview text scaling
            const node = shapeRef.current;
            node.setAttrs({
              width: node.width() * node.scaleX(),
              scaleX: 1,
            });
          }

        }}

        onTransformEnd={(e) => {

          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end

          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();


          let newShape = {
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            rotation: node.rotation(),
          }

          if(type === "rect"){
            newShape.width = Math.max(5, node.width() * scaleX)
            newShape.height = Math.max(node.height() * scaleY)
            node.scaleX(1)
            node.scaleY(1)


          } else if(type === "text" || type === "line") {
            newShape.width = Math.max(5, node.width() * scaleX)
            node.scaleX(1)
            node.scaleY(1)

          } else if( type === "circle" || type === "image" || type === "triangle") {
            newShape.scaleX = scaleX
            newShape.scaleY = scaleY

          }


          onChange(newShape);


        }}
      />

      {isSelected && (
        <Transformer
          enabledAnchors= {(type === "text" || type==="line") ? ['middle-left', 'middle-right'] : undefined}

          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {

            if(type==="text" || type === "line"){
              newBox.width = Math.max(30, newBox.width);

            }else if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }

            return newBox;
          }}
        />
      )}

    </React.Fragment>
  );
};


const ToolbarBtn = ({icon, onClick, title})=>{

  return <button type="button" title={title} className="toolbar-btn btn btn-outline-dark" onClick={onClick}>
    <i className={icon}/>
  </button>
}



const CanvasStage = ({value, onChange, size, fonts})=>{

  // Background: color or image
  //
  // Supported objects
  // - Text: Font, size, color, alignment, Bold/italic/underline, opacity
  // - Image: Opacity, Crop, replace
  // - Circle, rect, square, triangle, pentagon, line


  // Place: left, top, right, bottom, center verical/horizontal
  // Order: up, top, down, bottom
  // Loch item

  // Delete, duplicate, undo/redo
  // Right click copy, cut, paste

  // Save as template > my templates, gallery

  const dispatch = useDispatch()
  const stageRef = React.useRef();


  let initialData;
  if(value && value.shapes){
    initialData = value
  }else{
    initialData = {"shapes": []}
  }

  const [data, setData] = React.useState(initialData);

  const [selectedId, selectShape] = React.useState(null);


  // stage scaling
  let scaleWidth = (window.innerWidth * .6) / size.width
  let scaleHeight = (window.innerHeight * .75) / size.height

  let scale = Math.min(scaleWidth, scaleHeight)

  // callbacks
  const updateData = (data)=>{
    setData(data)
    onChange(data)
  }

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const addShape = (shape)=>{
    let newShapes = data.shapes.slice()

    shape = {
        ...shape,
        x: shape.x ? shape.x + 10 : 100,
        y: shape.y ? shape.y + 10 : 100,
        id: uuidv4(),
    }

    newShapes.push(shape)
    updateData({...data, shapes: newShapes})

    selectShape(shape.id)
  }

  const alignShape = (position)=>{


    // TODO: update this to work with rotated shapes and circles

    let newShapes = data.shapes.slice()

    let index = newShapes.findIndex((node)=>node.id === selectedId)
    let shape = {...newShapes[index]}


    const stage = stageRef.current.getStage()
    const node = stage.getLayers()[0].getChildren()[index]

    const bbox = node.getClientRect({skipTransform: true})

    switch(position){
      case "left":
        shape.x = 0
        break
      case "hcenter":
        shape.x = (size.width - bbox.width) * .5
        break
      case "right":
        shape.x = size.width - bbox.width
        break
      case "top":
        shape.y = 0
        break
      case "vcenter":
        shape.y = (size.height - bbox.height) * .5
        break
      case "bottom":
        shape.y = size.height - bbox.height
        break
      default:
        break
    }

    newShapes[index] = shape

    updateData({...data, shapes: newShapes})
  }

  const sortShape = (sorting)=>{

    let newShapes = data.shapes.slice()

    let index = newShapes.findIndex((node)=>node.id === selectedId)
    let shape = newShapes[index]

    // remove from array
    newShapes.splice(index, 1);

    let newIndex;

    if(sorting === "+"){
      newIndex = index + 1

    }else if(sorting === "++"){
      newIndex = newShapes.length

    }else if(sorting === "-"){
      newIndex = Math.max(0, index - 1)
    } else if(sorting === "--"){
      newIndex = 0;
    }

    newShapes.splice(newIndex, 0, shape)

    updateData({...data, shapes: newShapes})

  }

  const currentShape = React.useMemo(()=>{
    return data.shapes.find((node)=>node.id === selectedId)

  }, [data.shapes, selectedId])


  const font_choices = React.useMemo(()=>{
    return fonts.map((font, index)=>({id:font.name, label: font.name}))

  }, [fonts])



  return <div className="canvas-stage">
      <div className="canvas-main">
        <div className="toolbar">

          <ToolbarBtn icon={"fa fa-font"} title="Add text" onClick={(e)=>{
            addShape({
              type: "text",
              text: "New text",
              width: 200,
              fontSize: 40,
              fill: '#000000',
            })

          }}/>

          <ToolbarBtn icon={"fa fa-image"} title="Add image" onClick={(e)=>{

            dispatch({type: 'set', drawerForce: "media", drawerFieldCallback: (item)=>{

            } })

          }}/>


          <ToolbarBtn icon={"fa fa-circle"} title="Add circle" onClick={(e)=>{
            addShape({
              type: "circle",
              width: 100,
              height: 100,
              radius: 50,
              fill: '#417505',
              opacity: .5,
            })

          }}/>

          <ToolbarBtn icon={"fa fa-square"} title="Add rectangle" onClick={(e)=>{
            addShape({
              type: "rect",
              width: 100,
              height: 100,
              fill: '#4A90E2',
              opacity: .5,
            })


          }}/>


          <ToolbarBtn icon={"fa fa-caret-right"} title="Add triangle" onClick={(e)=>{
            addShape({
              type: "triangle",

              sides: 3,
              radius: 80,
              rotation: 90,

              fill: '#F5A623',
              opacity: .5,
            })


          }}/>


          {

            // <div className="toolbar-divider"/>
            //
            // <ToolbarBtn icon={"fa fa-undo"} onClick={(e)=>{
            //
            // }}/>
            //
            // <ToolbarBtn icon={"fa fa-redo"} onClick={(e)=>{
            //
            // }}/>

          }

          {currentShape &&
            <>

              <div className="toolbar-divider ml-auto"/>

              <ToolbarBtn icon={"fa fa-clone"} title="Duplicate selected" onClick={(e)=>{
                if(currentShape){
                  // let node = data.shapes.find((node)=>node.id === selectedId)
                  addShape(currentShape)
                }

              }}/>

              <ToolbarBtn icon={"fa fa-trash"} title="Remove selected" onClick={(e)=>{

                if(selectedId){

                  let newShapes = data.shapes.slice()
                  let index = newShapes.findIndex((node)=>node.id === selectedId)
                  newShapes.splice(index, 1);
                  updateData({...data, shapes: newShapes})

                  selectShape(null)

                }

              }}/>
          </>
        }


        </div>

        <CanvasDropContext addShape={addShape}>
          {({isOver, dropRef})=>(
            <div ref={dropRef}>

              <Stage
                ref={stageRef}
                width={size.width*scale}
                height={size.height*scale}
                className="stage-container"

                scale={{x: scale, y: scale}}
                style={{backgroundColor: isOver ? "#9CBEE9" : (data.backgroundColor || "#ffffff"), backgroundImage: data.backgroundImage ? `url("${data.backgroundImage.type === "image" ? data.backgroundImage.url : data.backgroundImage.thumbnail}")` : null }}
                onMouseDown={checkDeselect}
                onTouchStart={checkDeselect}
              >

              <Layer>
                {data.shapes && data.shapes.map((shape, i) => {
                  return (
                    <Shape
                      type={shape.type}
                      key={i}
                      shapeProps={shape}
                      isSelected={shape.id === selectedId}

                      onSelect={() => {

                        selectShape(shape.id);

                      }}

                      onChange={(newAttrs) => {
                        const newShapes = data.shapes.slice();
                        newShapes[i] = newAttrs;
                        updateData({...data, shapes: newShapes});

                      }}
                    />
                  );
                })}
              </Layer>
            </Stage>
          </div>
        )}
      </CanvasDropContext>

    </div>

    <div className="canvas-sidebar">
      <Form initialValues={currentShape || data} onSubmit={(values)=>{}} render={({ handleSubmit, submitError, submitting, form }) => (
          <>
            <FormSpy onChange={({values, dirty})=>{

              if(! dirty){
                return
              }

              if(currentShape){

                let index = data.shapes.findIndex((shape)=>shape.id === currentShape.id)
                let shapes = data.shapes.slice()
                shapes[index] = values
                updateData({...data, shapes})

              }else{
                let newData ={...data}
                newData.backgroundColor = values.backgroundColor
                newData.backgroundImage = values.backgroundImage
                updateData(newData)

              }

            }}/>

            {! currentShape &&
              <>
                <ColorField name="backgroundColor" label="Background color" mode="hex"/>
                <MediaField name="backgroundImage" label="Background image"/>
              </>
            }

            {currentShape &&
              <>

                <div className="align-btns mb-1">
                  <button type="button" title="Align left" onClick={()=>{alignShape("left")}} className="btn btn-sm mr-1"><AlignLeftIcon/></button>
                  <button type="button" title="Align horizontally center" onClick={()=>{alignShape("hcenter")}} className="btn btn-sm mr-1"><AlignHCenterIcon/></button>
                  <button type="button" title="Align right" onClick={()=>{alignShape("right")}} className="btn btn-sm mr-1"><AlignRightIcon/></button>
                  <button type="button" title="Align top" onClick={()=>{alignShape("top")}} className="btn btn-sm mr-1"><AlignTopIcon/></button>
                  <button type="button" title="Align vertically center" onClick={()=>{alignShape("vcenter")}} className="btn btn-sm mr-1"><AlignVCenterIcon/></button>
                  <button type="button" title="Align bottom" onClick={()=>{alignShape("bottom")}} className="btn btn-sm mr-1"><AlignBottomIcon/></button>
                </div>

                <div className="sort-btns">
                  <button type="button" title="Move up" onClick={()=>{sortShape("+")}} className="btn btn-outline-dark btn-sm mr-1"><i className="fa fa-arrow-up"/></button>
                  <button type="button" title="Move furthest up" onClick={()=>{sortShape("++")}} className="btn btn-outline-dark btn-sm mr-1"><i className="fa fa-sort-amount-up"/></button>
                  <button type="button" title="Move down" onClick={()=>{sortShape("-")}} className="btn btn-outline-dark btn-sm mr-1"><i className="fa fa-arrow-down"/></button>
                  <button type="button" title="Move furthest down" onClick={()=>{sortShape("--")}} className="btn btn-outline-dark btn-sm mr-1"><i className="fa fa-sort-amount-down"/></button>
                </div>

                <hr/>
                { currentShape.type !== "image" &&
                  <ColorField name="fill" label="Fill" mode="hex"/>
                }
                <RangeField name="opacity" label="Opacity" percent={true}/>
              </>
            }



            {currentShape && currentShape.type === "text" &&
              <>


                <TextField name="fontFamily" label="Font" choices={font_choices}/>
                <RangeField name="fontSize" label="Size" max={150} min={1} percent={false}/>

                <div className="d-flex">
                  <div className="form-group mr-1">

                    <label>Alignment</label>
                    <div>
                      <Field name="align">
                        {({input, meta}) => (
                          <div className="btn-group btn-group-sm">
                            <button type="button" onClick={()=>{input.onChange("left")}} className="btn btn-dark"><i className="fa fa-align-left"/></button>
                            <button type="button" onClick={()=>{input.onChange("center")}} className="btn btn-dark"><i className="fa fa-align-center"/></button>
                            <button type="button" onClick={()=>{input.onChange("right")}} className="btn btn-dark"><i className="fa fa-align-right"/></button>
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Style</label>
                    <div>
                      <div className="btn-group btn-group-sm">
                        <Field name="fontStyle">
                        {({input, meta}) => (
                          <>
                            <button type="button" onClick={()=>{input.onChange(input.value === "bold" ? "" : "bold")}} className="btn btn-dark"><i className="fa fa-bold"/></button>
                            <button type="button" onClick={()=>{input.onChange(input.value === "italic" ? "" : "italic")}} className="btn btn-dark"><i className="fa fa-italic"/></button>
                          </>
                        )}
                        </Field>
                        <Field name="textDecoration">
                          {({input, meta}) => (
                            <button type="button" onClick={()=>{input.onChange(input.value ? "" : "underline")}} className="btn btn-dark"><i className="fa fa-underline"/></button>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>

                </div>

                <TextArea name="text" label="Text"/>
              </>
            }




            {currentShape && currentShape.type === "image" &&
              <>


                <div className="form-group">

                  <label>Crop</label>
                  <div>
                    <Field name="crop">
                      {({input, meta}) => (
                        <div className="btn-group btn-group-sm">
                          <button type="button" onClick={()=>{input.onChange("circle")}} className="btn btn-dark"><i className="fa fa-circle"/></button>
                          <button type="button" onClick={()=>{input.onChange("rect")}} className="btn btn-dark"><i className="fa fa-square"/></button>
                          <button type="button" onClick={()=>{input.onChange(null)}} className="btn btn-dark"><i className="fa fa-times"/></button>
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </>
            }


          </>
      )}/>


    </div>

  </div>

}



const CanvasField = ({name, size})=>{

  const [fonts, setFonts] = React.useState()

  React.useEffect(()=>{

    const load = async ()=>{
      let response = await service.list("fonts")

      for(let font of response.data.results){
        let link = document.createElement('link');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('type', 'text/css');
        link.setAttribute('href', font.font_url);
        document.head.appendChild(link);
      }

      setFonts(response.data.results)
    }

    load()


  }, [])

  if(! fonts){
    return <span className="spinner-border"/>
  }

  return (
    <Field name={name}>
    {({input})=>(

      <CanvasStage onChange={input.onChange} value={input.value} size={size} fonts={fonts}/>

    )}</Field>
  );


}


export default CanvasField
