
import React from 'react'
import { debounce } from 'throttle-debounce';

import { Form, Field, FormSpy } from 'react-final-form'
import CIcon from '@coreui/icons-react'
import { OnChange } from 'react-final-form-listeners'
import service from "admin-kit/service"



// const Preview = ({data, template})=>{
//
//   const previewFrame = React.useRef(null);
//
//   const updatePreview = React.useCallback(debounce(500, false, async (value) => {
//
//     console.log(previewFrame, value)
//
//     if(previewFrame.current){
//
//       const message = {type:"data", data:value, media: {}}
//       previewFrame.current.contentWindow.postMessage(JSON.stringify(message), '*')
//     }
//   }), [])
//
//   return <iframe
//       onLoad={()=>{
//         updatePreview(data || {})
//       }}
//       ref={previewFrame}
//       className="message-preview"
//       src={template.preview_url}
//     />
//
// }
//




const parseValueForMedia = (value, media)=>{

  for(let item of Object.values(value)){

    if(! item){
      continue
    }

    if(Array.isArray(item)){

      for(let child of item){
        parseValueForMedia(child, media)
      }

    }else if(item.id && item.type){
      // Should be media
      media[item.id] = item

    }else if(item instanceof Object){
      parseValueForMedia(item, media)
    }
  }

}


const AppPreview = ({name, app})=>{


  const previewFrame = React.useRef(null);
  const [loading, setLoading] = React.useState(true)
  const [mediaUrls, setMediaUrls] = React.useState({})
  const initialData = React.useRef()

  const [background, setBackground] = React.useState(null)

  const updatePreview = React.useCallback(debounce(500, false, async (value) => {

    let data = value.data ? {...value.data} : {}

    if(value.theme){
      data.theme = value.theme.data
    }


    const message = {
      type:"data",
      data:data,
      media: {},
      proxy_url: `${window.location.origin}/api/proxy/v2/get/`
    }

    // Find media used in message
    const usedMedia = {}

    if(data){

      parseValueForMedia(data, usedMedia)

      // Check if we need to ferch addional info about media we found
      const ids = Object.keys(usedMedia).filter((id)=>!mediaUrls[id])

      let resolvedMediaUrls = mediaUrls
      if(ids.length){
        let response = await service.action_list_get("media", "get_revisions_for_ids", {ids: ids.join(",")})
        resolvedMediaUrls = {...response.data, ...mediaUrls}

        setMediaUrls(resolvedMediaUrls)
      }

      // Add media urls to message
      for(let id of Object.keys(usedMedia)){
        if(resolvedMediaUrls[id]){
          message.media[id] = {
            url: `${app.base_url}${resolvedMediaUrls[id]}`
          }
        }
      }


      if(data.theme && data.theme.background && data.theme.background.id){
        let backgroundMedia = data.theme.background
        if(backgroundMedia.type === "image"){
          setBackground(data.theme.background.url)
        }else{
          setBackground(data.theme.background.thumbnail)

        }

      }else {
        setBackground(null)
      }

    }

    const messageAsString = JSON.stringify(message)

    console.log(messageAsString)

    previewFrame.current.contentWindow.postMessage(messageAsString, '*')

  }), [mediaUrls])



  return <>
    {loading && <span className="spinner-border"/>}

    <Field name={name}>
      {({input})=>{

        return <iframe
          title="app preview"
          style={{backgroundImage: background ? `url("${background}")` : undefined, backgroundSize: "cover", backgroundPosition: "center"}}
          onLoad={()=>{
            setLoading(false)

            // let data = input.value || {}

            console.log("loaded", initialData)
            updatePreview(initialData.current || {})
          }}
          ref={previewFrame}
          className="message-preview"
          src={app.preview_url}
        />
      }}
    </Field>

    <FormSpy
      subscription={{ values: true }}
      onChange={props => {
        // console.log("formspy", props.values)
        if(loading){
          initialData.current = props.values

        }else{
          updatePreview(props.values)
        }

      }}
    />

    {

    // <OnChange name={"theme.data"}>
    // {(value, previous) => {
    //   // console.log(value)
    // }}
    // </OnChange>
    //
    // <OnChange name={name}>
    // {(value, previous) => {
    //
    //   if(previewFrame){
    //     updatePreview(value)
    //   }
    // }}
    // </OnChange>
    }
  </>

}


export default AppPreview
