import React from 'react'
import { useHistory } from "react-router-dom";


import { Form, Field, FormSpy } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import {ActionButton} from "../../components/cells"

import { CCard, CCardBody, CCardHeader, CCardFooter, CCol, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import {useTranslation} from "../../context/trans"

import service from "../../service"

import { useSelector, useDispatch } from 'react-redux'
import arrayMutators from "final-form-arrays";

import Fields from "../../components/fields"
import Fieldset from "../../components/fieldset"




const DetailBody = ({submitError, resource, object, form, modelConfig, customRenderField})=>{

  let [currentTab, setCurrentTab] = React.useState("")

  React.useEffect(()=>{
    if(resource.detail.layout){
      setCurrentTab(Object.keys(resource.detail.layout)[0])
    }
  } ,[])


  const allFields = React.useMemo(()=>{

    let allFields = resource.detail.fields

    if(! allFields && modelConfig){
      allFields = Object.values(modelConfig.fields)
    }
    return allFields

  }, [resource.detail.fields, modelConfig])



  const fieldsByName = React.useMemo(()=>{

    let fieldOverrides = resource.detail.fieldOverrides || {}

    let fieldsByName = {}
    for(let field of allFields){

      let fieldOverride = fieldOverrides[field.name]
      if(fieldOverride){
        field = {...field, ...fieldOverride}
      }

      fieldsByName[field.name] = field
    }
    return fieldsByName

  }, [resource.detail.fieldOverrides])



  if(! resource.detail.layout){
    return <Fieldset>

      <Fields fields={allFields} resource={resource} customRenderField={customRenderField}/>
    </Fieldset>
  }

  const fieldsFromFieldNames = (fieldNames)=>{
    let fields = []
    for(let fieldName of fieldNames){
      let field = fieldsByName[fieldName]
      if(! field){
        // Support custom fields, like read only fields
        field = {
          name: fieldName
        }
      }

      fields.push(field)
    }
    return fields
  }

  const tabs = Object.entries(resource.detail.layout)
  return <div>
    {tabs.length > 1 &&
    <ul className="nav nav-pills my-2">
      {tabs.map(([tab_name, children], index)=>{
        return <li className="nav-item" key={index}>
          <a className={`nav-link ${tab_name===currentTab ? "active" : ""}`} href="#" onClick={(event)=>{
            event.preventDefault()
            setCurrentTab(tab_name)
          }}>{tab_name}</a>
        </li>
      })}
    </ul>
    }

    {submitError && <div className="alert alert-danger">{submitError}</div>}


    {Object.entries(resource.detail.layout).map(([tab_name, children], index)=>{
      // Render all tabs so errors are visible on all fields!
      return Object.entries(resource.detail.layout[tab_name]).map(([fieldsetName, fieldNames], index)=>{
        return <div key={index} style={{display: tab_name === currentTab ? "block":"none"}}>
          <Fieldset label={fieldsetName}>
            <Fields fields={fieldsFromFieldNames(fieldNames)} resource={resource} customRenderField={customRenderField}/>
          </Fieldset>
        </div>
      })

    })}


  </div>
}



const GenericDetail = ({match, resource, initialValues, BodyComponent, preSaveCallback}) => {

  const [object, setObject] = React.useState(null)
  const [modelConfig, setModelConfig] = React.useState(null)
  const dispatch = useDispatch()

  const [isDeleting, setIsDeleting] = React.useState(false)
  const [reloadCounter, setReloadCounter] = React.useState(0)
  const triggerReload = ()=>{
    // Reload list, can be triggered from actions
    setReloadCounter(reloadCounter +1)
  }

  const {t} = useTranslation()

  React.useEffect(() => {
    const fetchConfig = async (id) => {
      let data = await service.meta(resource.name)
      setModelConfig(data)
    }
    fetchConfig()
  }, [])



  React.useEffect(() => {
    setObject(null)
    const fetchObject = async (id) => {
      let response = await service.get(resource.name, id)
      setObject(response.data)
    }

    if(match.params.id){
      fetchObject(match.params.id)
    }else{
      console.log(initialValues)
      setObject(initialValues || {})
    }
  }, [match.params.id, reloadCounter])


  React.useEffect(()=>{
    if(resource.detail.drawer){
      dispatch({type: 'set', drawerShow:resource.detail.drawer })
      return ()=>dispatch({type: 'set', drawerShow:null })
    }
  })

  const history = useHistory();


  const onDelete = async(event) => {
      if(isDeleting){
        return
      }

      if(! window.confirm("Are you sure?")){
        return
      }
      setIsDeleting(true)

      try {
        let response = await service.delete(resource.name, object.id)

        dispatch({type: 'ADD_TOAST', toast: {"message": `Deleted ${object.name}`, "type": "success"} })

        history.push(`/${resource.name}`)


      }catch(error){
        dispatch({type: 'ADD_TOAST', toast: {"message": error.message, "type": "danger"} })
      }

      setIsDeleting(false)


  }

  const onSubmit = async(values) => {

    const id = values.id
    try {
      let response

      if(preSaveCallback){
        values = preSaveCallback(values)
      }

      if(id){
        response = await service.update(resource.name, values.id, values)
        setObject(response.data)

      }else{
        response = await service.create(resource.name, values)
        history.push(`/${resource.name}/${response.data.id}`)


      }


      dispatch({type: 'ADD_TOAST', toast: {"message": "Success", "type": "success"} })

    } catch(error){

      if(error.response && error.response.status === 400){


        let errors = error.response.data

        // if("non_field_errors"){
        //   errors[FORM_ERROR] = errors["non_field_errors"]
        // }

        dispatch({type: 'ADD_TOAST', toast: {"message": "Check errors", "type": "danger"} })
        return errors

      }

      dispatch({type: 'ADD_TOAST', toast: {"message": error.message, "type": "danger"} })
      return { [FORM_ERROR]: error.message }


    }
  }




  return (
    <CRow>
      <CCol>
        <div>
          {(object && modelConfig) &&
            <Form
              mutators={{
                ...arrayMutators
              }}
              onSubmit={onSubmit}
              // validate={validate}
              initialValues={object}

              subscription={{ submitError: true, errors:true}}

              render={({ handleSubmit, submitError, submitting, form }) => (
                <form onSubmit={handleSubmit} >

                  <h4>
                    {object.id ? `${t("Update")} ${t(resource.name)} #${object.id}` : `${t("New")} ${t(resource.name)}`}
                  </h4>

                  {BodyComponent ?
                    <BodyComponent submitError={submitError} resource={resource} object={object} form={form}  modelConfig={modelConfig} initialValues={object}/> :
                    <DetailBody submitError={submitError} resource={resource} object={object} form={form} modelConfig={modelConfig} initialValues={object}/>
                  }

                  <CCardFooter className="mt-2 detail-actions">
                    <FormSpy subscription={{ dirty: true, submitting:true }}>
                      {({ dirty, submitting }) =>

                      <CRow>
                        <CCol>
                          {object.id &&
                            <button className="btn btn-danger" type="button" onClick={onDelete}>
                              {isDeleting ? <span className="spinner-border spinner-border-sm mr-1"/> : <CIcon name="cil-trash" className="mr-1"/>} {t("Delete")}
                            </button>
                          }


                        </CCol>
                        <CCol  className="justify-content-end d-flex">

                          {object.id && resource.detail.actions &&
                            resource.detail.actions.map(({label, name, confirm}, index)=>{
                              return <ActionButton key={index} disabled={dirty} BtnComponent={"button"} className={"btn btn-outline-primary btn-sm mr-2 action-btn"} name={name} label={t(label)} confirm={confirm} resource={resource} row={object} triggerReload={triggerReload}/>
                            })
                          }

                          <button className="btn btn-success mx-1" type="submit" disabled={submitting || !dirty}>
                            {submitting ? <span className="spinner-border spinner-border-sm mr-1"/> : <CIcon name="cil-save" className="mr-1"/>}
                            {t("Save")}
                          </button>

                        </CCol>
                      </CRow>
                      }
                    </FormSpy>

                  </CCardFooter>

                </form>
              )}
            />
          }

          {
            (! object || ! modelConfig ) && <div className="d-flex py-3 justify-content-center"><span className="spinner-border"/></div>
          }

        </div>
      </CCol>
    </CRow>
  )
}

export default GenericDetail


export {
  DetailBody
}
