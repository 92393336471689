import React from 'react';
import {Field} from 'react-final-form'
import GenericDetail, {DetailBody} from "admin-kit/resources/base/detail"
import { Link, NavLink } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import service from  "admin-kit/service"

import ChannelArea from "../../components/fields/channel"
import PlaylistField from "../../components/fields/playlist"

import {ScreenSizeCreate} from "../canvas/detail"




const ChannelBody = ({resource, ...props})=>{
  return <DetailBody
        resource={resource}
        {...props}
        customRenderField={({field, prefix, resource, renderField})=>{

    if(field.type === "playlist"){
      return <PlaylistField name={field.name} label={field.label} key={field.name} />

    }else if(field.type === "frameset"){

      return <ChannelArea name={field.name} label={field.label} key={field.name} width={props.object.width} height={props.object.height}/>

    }

    return renderField({field, prefix, resource})

  }}/>

}


const ChannelCreate = (props)=>{
  return <ScreenSizeCreate {...props} BodyComponent={ChannelBody}/>
}


const ChannelEdit = (props)=>{
  return <GenericDetail {...props} BodyComponent={ChannelBody}/>
}



export default ChannelCreate
export {ChannelBody, ChannelEdit}
