import React from 'react';

import AdminApp from "admin-kit/app"

import './scss/style.scss';

import {useTranslation} from "admin-kit/context/trans"

import nav from "./nav"
import getRoutes from "./routes"

const App = (props)=>{

  const {t} = useTranslation()

  const routes = React.useMemo(()=>{
    console.log("get routes")
    return getRoutes(t)
  }, [t])


  return <AdminApp {...props} nav={nav} routes={routes}/>

}

export default App
