import React from 'react'

import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"


// https://github.com/final-form/react-final-form/issues/130
// empty string is not sent
const identity = value => (value);



const RatioField = ({name, label, placeholder, type, extraClassName, initialValue}) => {

  const fieldId = `input-${name}`

  let className = `form-control ${extraClassName || ""}`
  return <Field name={name} initialValue={initialValue} parse={identity}>
    {({input, meta}) => (
      <BootstrapField label={label} fieldId={fieldId} meta={meta}>

        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">%</span>
          </div>

          <input value={input.value ? Number(input.value) * 100 : ""} onChange={(event)=>{
            const value = event.target.value
            input.onChange(value/100)

          }} type={"number"} className={className} id={fieldId} placeholder={placeholder} />
        </div>

      </BootstrapField>
    )}
  </Field>
}

export default RatioField
