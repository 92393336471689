
import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"
import {CIcon} from '@coreui/icons-react'

import { useDrop } from 'react-dnd'


const getListStyle = (isOver) => ({
  background: (isOver) ? "lightblue" : null,

})

const DropContext = ({children, onDrop})=> {

  const [{ isOver }, drop] = useDrop({
    accept: "media",
    drop: async (item, monitor) => {
      onDrop(item)

    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })

  return children({isOver, dropRef:drop})

}

const MediaField = ({name, label, initialValue}) => {

  const fieldId = `input-${name}`

  const dispatch = useDispatch()

  return <Field name={name} initialValue={initialValue}>
    {({input, meta}) => (
      <BootstrapField label={label} fieldId={fieldId} meta={meta}>



        <DropContext onDrop={(item)=>{
          input.onChange(item.media)
        }}>
        {({isOver, dropRef})=>(

        <div ref={dropRef}>
        <div className="media-thumbnail media-field" style={getListStyle(isOver)} onClick={()=>{
            dispatch({type: 'set', drawerForce: "media", drawerFieldCallback: input.onChange })
        }}>
        {input.value ?
          <img src={input.value.thumbnail}/>
          : <CIcon name="cil-image" size="lg"/>
          }
        </div>
        <div className="name">
          {input.value &&
            <>
              {input.value.name}
              <span onClick={()=>{input.onChange(null)}} className="remove-media ml-2"><CIcon name="cil-trash" /></span>
            </>
          }
        </div>
        </div>
      )}
      </DropContext>

      </BootstrapField>
    )}
  </Field>
}

export default MediaField
