import React from 'react'

import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"



const CheckboxField = ({name, label, initialValue})=>{

  const fieldId = `input-${name}`

  return <Field name={name} type="checkbox" initialValue={initialValue}>
    {({input, meta}) => (

      <div className="form-check my-2">
        <input type="checkbox" className="form-check-input" id={fieldId} {...input}/>
        <label className="form-check-label" htmlFor={fieldId}>{label}</label>
      </div>
    )}
  </Field>


}

export default CheckboxField
