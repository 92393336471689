import React from 'react'

import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"


// https://github.com/final-form/react-final-form/issues/130
// empty string is not sent
const identity = value => (value);



const TextField = ({name, label, placeholder, type, extraClassName, choices, initialValue, required}) => {

  const fieldId = `input-${name}`

  let className = `form-control ${extraClassName || ""}`
  return <Field name={name} initialValue={initialValue} parse={identity}>
    {({input, meta}) => (
      <BootstrapField label={label} fieldId={fieldId} meta={meta}>
        {choices ?

          <select {...input} className={className} id={fieldId} required={required}>
            <option value="">-</option>
            {choices.map((choice, index)=>
              <option key={index} value={choice.id}>{choice.label}</option>
            )}
          </select>:
          <input {...input} type={type || "text"} className={className} id={fieldId} placeholder={placeholder} required={required} />
        }
      </BootstrapField>
    )}
  </Field>
}

export default TextField
