import React from 'react'

import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"



// https://github.com/final-form/react-final-form/issues/130
// empty string is not sent
const identity = value => (value);


const TextAreaField = ({name, label, placeholder, type}) => {

  const fieldId = `input-${name}`

  return <Field name={name} parse={identity}>
    {({input, meta}) => (
      <BootstrapField label={label} fieldId={fieldId} meta={meta}>
        <textarea {...input} className="form-control" id={fieldId} placeholder={placeholder}/>
      </BootstrapField>
    )}
  </Field>
}

export default TextAreaField
