import React from 'react';

import { Field } from 'react-final-form'

import GenericDetail, {DetailBody} from "admin-kit/resources/base/detail"

import service from  "admin-kit/service"

import {useTranslation} from "admin-kit/context/trans"

import AppInstanceField from "../../components/fields/app-instance"


const AppInstanceBody = ({resource, initialValues, ...props})=>{

  // console.log(initialValues)
  const {t} = useTranslation()



  return <DetailBody resource={resource} {...props} customRenderField={({field, prefix, resource, renderField})=>{
    let app

    if(initialValues){
      if(initialValues.app){
        app = initialValues.app.current_version
      }else{
        app = initialValues.custom_app
      }
    }

    if(field.type === "message"){

      if(app){
        return <AppInstanceField name={field.name} label={t(field.label)} key={field.name} app={app} show_preview={true}/>
      }else{
        return null
      }

    }else if(field.name === "theme"){

      if(!app || !app.theme_data || !app.theme_data.content || !app.theme_data.content.length){
        return null
      }

    }

    return renderField({field, prefix, resource})

  }}/>

}





const AppSelectDetail = (props)=>{

  const [customApps, setCustomApps] = React.useState([])
  const [apps, setApps] = React.useState([])

  const [loading, setLoading] = React.useState([])

  const [selectedApp, setSelectedApp] = React.useState(null)
  const [selectedCustomApp, setSelectedCustomApp] = React.useState(null)

  const [initialData, setInitialData] = React.useState()
  const {t} = useTranslation()
  React.useEffect(()=>{

    const loadTemplates = async ()=>{

        let response = await service.list("custom-apps", {})
        setCustomApps(response.data.results)

        response = await service.list("apps", {"available_for_app_instance": 1})
        setApps(response.data.results)

        setLoading(false)
    }
    loadTemplates()

  }, [])


  const initialValuesForFields = (fields)=>{
    let data = {}
    for(let field of fields){

      if(field.type === "group"){
        data[field.attributes.id] = initialValuesForFields(field.children)

      }else if(field.attributes?.id && field.attributes?.initial_value){
        data[field.attributes.id] = field.attributes?.initial_value

      }
    }

    return data
  }

  const updateInitialData = (app)=>{
    // field.initialValue seems to be buggy in final form so we create a dict with all initial values

    let appData = app.current_version ? app.current_version.data : app.data
    if(appData && appData.content){
      let data = initialValuesForFields(appData.content)
      setInitialData(data)
    }
  }



  return <>
    {(selectedApp || selectedCustomApp) ?
      <GenericDetail {...props} initialValues={{custom_app: selectedCustomApp, app: selectedApp, type: selectedApp ? "app" : "custom-app", data: initialData }} BodyComponent={props.BodyComponent}/>
      :
      <div>
        {loading ? <span className="spinner-border"/> :
          <div>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{t("Custom apps")}</h5>

                {customApps.map((app, index)=>{
                  return <button type="button" className="btn btn-dark m-1" key={index} onClick={()=>{
                    updateInitialData(app)
                    setSelectedCustomApp(app)
                  }}>{app.name}</button>
                })}

              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{t("Apps")}</h5>
                  {apps.map((app, index)=>{
                    return <button type="button" className="btn btn-dark m-1" key={index} onClick={()=>{
                      updateInitialData(app)
                      setSelectedApp(app)

                    }}>{app.name}</button>
                  })}
              </div>
            </div>

          </div>
        }


      </div>

    }
  </>
}



const AppInstanceDetail = (props)=>{
  return  <AppSelectDetail {...props} BodyComponent={AppInstanceBody}/>
}


const AppInstanceEdit = (props)=>{

  return <GenericDetail {...props} BodyComponent={AppInstanceBody}/>
}


export default AppInstanceDetail
export {AppInstanceBody, AppInstanceEdit, AppSelectDetail}
