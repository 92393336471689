
import React from "react"

import { Form, Field } from 'react-final-form'

const DurationField = ({name})=>{
  return <Field name={name}>
  {({input})=>(

    <div className="input-group input-group-sm">
      <input type="number" className="form-control duration" {...input}/>
      <div className="input-group-append">
        <span className="input-group-text">s</span>
      </div>
    </div>

  )}</Field>
}


export default DurationField
