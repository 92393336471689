import React, { Component } from 'react';

import AsyncSelect from 'react-select/async';

import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"
import CreatableSelect from 'react-select/creatable';


const TagsField = ({name, label}) => {

  return <Field name={name}>
    {({input, meta}) => (
      <BootstrapField label={label} meta={meta}>
        <CreatableSelect
          isClearable
          isMulti
          onChange={(value)=>value ? input.onChange(value.map((value)=>value.value)) : null}

          value={
            input.value ?
            input.value.map((value)=>{return {label:value, value}}) : []
          }
        />
      </BootstrapField>
    )}
  </Field>


}

export default TagsField
