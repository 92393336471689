
import axios from 'axios';


const BASER_URL = "/api/v2"


class Service {
  constructor() {
    this._meta_cache = {}
    this._blocks_cache = {}

  }

  // Meta
  async meta(resource){
    if(! this._meta_cache[resource]){

      let response = await axios.get(`${BASER_URL}/${resource}/meta/`)
      this._meta_cache[resource] = response.data
    }
    return this._meta_cache[resource]
  }

  async blockbuilder_config(resource){
    if(! this._blocks_cache[resource]){

      let response = await axios.get(`${BASER_URL}/${resource}/blockbuilder_config/`)
      this._blocks_cache[resource] = response.data
    }
    return this._blocks_cache[resource]
  }

  // Single
  async get(resource, id){
    return await axios.get(`${BASER_URL}/${resource}/${id}/`)
  }

  async update(resource, id, data){
    return await axios.put(`${BASER_URL}/${resource}/${id}/`, data)
  }

  async partial(resource, id, data){
    return await axios.patch(`${BASER_URL}/${resource}/${id}/`, data)
  }

  async create(resource, data){
    return await axios.post(`${BASER_URL}/${resource}/`, data)
  }

  async delete(resource, id){
    return await axios.delete(`${BASER_URL}/${resource}/${id}/`)
  }



  // Many
  async autocomplete(resource, params){
    return axios.get(`${BASER_URL}/${resource}/autocomplete/`, {params})
  }

  async list(resource, params){
    return axios.get(`${BASER_URL}/${resource}/`, {params})
  }

  async more(next){
    return axios.get(next)
  }


  // Actions


  async action_list(resource, action, data, params, responseType){
    return axios.post(`${BASER_URL}/${resource}/${action}/`, data, {params, responseType})
  }

  async action_detail(resource, action, id, data){
    return axios.post(`${BASER_URL}/${resource}/${id}/${action}/`, data)
  }




  async action_list_get(resource, action, params){
    return axios.get(`${BASER_URL}/${resource}/${action}/`, {params})
  }

  async action_detail_get(resource, action, id, params){
    return axios.get(`${BASER_URL}/${resource}/${id}/${action}/`, {params})
  }



}


const service = new Service()



export default service;
