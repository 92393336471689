import React from 'react';


const Fieldset = ({label, children}) =>{
  return <div>
    {label && <h6 className="mt-3">{label}</h6>}
    <div className="fieldset">
      {children}
    </div>
  </div>
}

export default Fieldset
