import React from "react";

import { FieldArray } from "react-final-form-arrays";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Form, Field } from "react-final-form";
import CIcon from "@coreui/icons-react";
import BootstrapField from "admin-kit/components/fields/bootstrap";
import DurationField from "./duration";
import ContentPreview from "./content-preview";
import { useDrop } from "react-dnd";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver, isOver) => ({
  background: isDraggingOver || isOver ? "lightblue" : null,
  // opacity: isOver ? . : 1
});

const makeOnDragEndFunction = (fields) => (result) => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  fields.move(result.source.index, result.destination.index);
};

const DropContext = ({ children, onDrop }) => {
  const [{ isOver }, drop] = useDrop({
    accept: ["media", "link", "app_instance", "canvas"],
    drop: async (item, monitor) => {
      onDrop(item);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return children({ isOver, dropRef: drop });
};

const PlaylistField = ({ name, label }) => {
  return (
    <FieldArray name={name}>
      {({ fields, meta }) => (
        <BootstrapField label={label} meta={meta}>
          <DropContext
            onDrop={(item) => {
              let data = {
                content_type: item.type,
                duration: 10,
                [item.type]: item[item.type],
              };

              fields.push(data);
            }}
          >
            {({ isOver, dropRef }) => (
              <div ref={dropRef}>
                <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        className={"inline-items"}
                        style={getListStyle(snapshot.isDraggingOver, isOver)}
                      >
                        {fields.map((name, index) => (
                          <Draggable
                            key={name}
                            draggableId={name}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={`inline-item ${
                                  fields.value[index].delete ? "delete" : ""
                                }`}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <span
                                  {...provided.dragHandleProps}
                                  className="drag-handle"
                                >
                                  <CIcon name="cil-swap-vertical" />
                                </span>

                                <div className="inline-content">
                                  <ContentPreview data={fields.value[index]} />
                                </div>

                                <div className="inline-tools">
                                  <div>
                                    {fields.value[index].media &&
                                    fields.value[index].media.duration ? (
                                      <h4>
                                        <span className="badge badge-secondary  mx-2">
                                          {fields.value[index].media.duration}s
                                        </span>
                                      </h4>
                                    ) : (
                                      <DurationField
                                        name={`${name}.duration`}
                                      />
                                    )}
                                  </div>

                                  <span
                                    onClick={() => {
                                      let data = fields.value[index];

                                      if (!data.id) {
                                        // Item is not persisted in db yet
                                        fields.remove(index);
                                      } else {
                                        let newData = Object.assign({}, data);
                                        newData.delete = !newData.delete;
                                        fields.update(index, newData);
                                      }
                                    }}
                                    className="tool-btn"
                                  >
                                    {fields.value[index].delete ? (
                                      <CIcon name="cil-action-undo" />
                                    ) : (
                                      <CIcon name="cil-trash" />
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}

                        {!(fields.value && fields.value.length) && (
                          <div className="empty-list lead">
                            Drop content here
                          </div>
                        )}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </DropContext>
        </BootstrapField>
      )}
    </FieldArray>
  );
};

export default PlaylistField;
