import React, { Component } from 'react';
import {useTranslation} from "admin-kit/context/trans"

const BootstrapField = ({meta, label, children, fieldId, name, ignoreErrors, helpText})=> {
  const {t} = useTranslation()

  return (

    <div className={`form-group ${(meta.error || meta.submitError) && meta.touched && !ignoreErrors && 'is-invalid'}`}>
      {label && <label htmlFor={fieldId}>{t(label)}</label>}
      {children}
      {(meta.error || meta.submitError) && meta.touched && !ignoreErrors &&(
        <div className="invalid-feedback">{String(meta.error || meta.submitError)}</div>
      )}
      {helpText && <small className="form-text text-muted">{helpText}</small>}
    </div>
  );

}


export default BootstrapField
