import React, { Component } from 'react';
import { SketchPicker } from 'react-color'
import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"



class ColorField extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleChange(color, event) {

    if(this.props.mode === "hex"){
      this.props.onChange(color.hex )
    }else{

      this.props.onChange(color.rgb )
    }
  }

  handleClick(){
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose(){
    this.setState({ displayColorPicker: false })
  };

  render() {
    let color
    let colorStyle

    if(this.props.mode === "hex"){
      color = this.props.value
      colorStyle = {backgroundColor: color}

    }else{

      color = this.props.value || {r:0, g:0, b:0, a:0}
      colorStyle = {backgroundColor: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`}

    }


    return <div>
       <div style={ styles.swatch } className="color-field-background" onClick={ this.handleClick }>
         <div style={colorStyle} className="color-field-preview" />
       </div>
       { this.state.displayColorPicker ? <div style={ styles.popover }>
         <div style={ styles.cover } onClick={ this.handleClose }/>
         <SketchPicker color={this.props.value} onChange={this.handleChange}/>
       </div> : null }

    </div>


  }
}

const styles = {

  swatch: {
    padding: '5px',
    // background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '1001',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },

}

const WrappedColorField = ({name, label, mode})=> {
  return <Field name={name}>
    {({input, meta}) => (
      <BootstrapField label={label} meta={meta}>
        <ColorField {...input} mode={mode}/>
      </BootstrapField>
    )}
  </Field>
}
export default WrappedColorField;
