import React from 'react';
import GenericDetail, {DetailBody} from "admin-kit/resources/base/detail"


import { Stage, Layer, Rect, Transformer } from 'react-konva';



import CanvasField from "../../components/fields/canvas"

const CanvasBody = ({resource, ...props})=>{
  return <DetailBody
        resource={resource}
        {...props}
        customRenderField={({field, prefix, resource, renderField})=>{

    if(field.name === "data"){
      return <CanvasField name={field.name} label={field.label} key={field.name} size={{width: props.object.width, height: props.object.height}}/>

    }

    return renderField({field, prefix, resource})

  }}/>

}



const ScreenSizeCreate = (props)=>{

  const presets = [
    {name: "Landscape", label: "FULL HD (1920 x 1080)", icon: "image", width: 1920, height: 1080},
    {name: "Portrait", label: "FULL HD (1080 x 1920)", icon: "portrait", width: 1080, height: 1920}
  ]
  const [selectedPreset, setSelectedPreset] = React.useState()
  const [moveAlong, setMoveAlong] = React.useState()

  return <>
    {(moveAlong && selectedPreset !== undefined) ?
      <GenericDetail {...props} initialValues={{width: presets[selectedPreset].width, height: presets[selectedPreset].height}} BodyComponent={props.BodyComponent}/>
      :
      <div className="fieldset canvas-presets">
        <h3>Choose preferred orientation</h3>
        <p>Choose layout orientation based on your content to get the best content display on your screens.</p>

        <div className="d-flex justify-content-center">
          {presets.map((preset, index)=>{

            return <div key={index} className={`preset p-3 border rounded m-2 d-flex flex-column align-items-center ${index === selectedPreset ? "bg-primary" : "bg-dark"}`} onClick={()=>{setSelectedPreset(index)}}>
              <i className={`fa fa-${preset.icon}`}/>
              <h4>{preset.name}</h4>
              <small>{preset.label}</small>
            </div>
          })}
        </div>

        {selectedPreset !== undefined &&
            <div className="mt-2 d-flex justify-content-center">
              <button className="btn btn-success btn-lg" type="button" onClick={()=>{setMoveAlong(true)}}>Continue</button>
            </div>
        }

      </div>

    }
  </>
}


const CanvasCreate = (props)=>{
  return <ScreenSizeCreate {...props} BodyComponent={CanvasBody}/>
}


const CanvasEdit = (props)=>{
  return <GenericDetail {...props} BodyComponent={CanvasBody}/>
}

export default CanvasCreate
export {CanvasBody, CanvasEdit, ScreenSizeCreate}
