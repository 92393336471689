import React from 'react'

import { Field } from 'react-final-form'
import BootstrapField from "./bootstrap"
import { OnChange } from 'react-final-form-listeners'


// https://github.com/final-form/react-final-form/issues/130
// empty string is not sent
const identity = value => (value);


const slugify = (text) => {
   return text.replace(/[^-a-zA-Z0-9\s+]+/ig, '').replace(/\s+/gi, "-").toLowerCase();
  }

const SlugField = ({name, label, placeholder, type, extraClassName, choices, initialValue}) => {

  const fieldId = `input-${name}`

  const onChange = (event)=>{

  }

  let className = `form-control ${extraClassName || ""}`
  return <Field name={name} initialValue={initialValue} parse={identity}>
    {({input, meta}) => (
      <BootstrapField label={label} fieldId={fieldId} meta={meta}>

        <input value={input.value} onFocus={input.onFocus} onBlur={input.onBlur} onChange={(event)=>{
          input.onChange(slugify(event.target.value))
        }} type={"text"} className={className} id={fieldId} placeholder={placeholder} />


        <OnChange name="name">
          {(value, previous) => {
            if(!meta.initial && !meta.touched){
              input.onChange(slugify(value))
            }
          }}
        </OnChange>

      </BootstrapField>
    )}
  </Field>
}

export default SlugField
