import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';


import {handleToast} from "admin-kit/store"

const initialState = {
  sidebarShow: 'responsive',

  toasts: [],
  toastId: 0,

  drawerShow: null,
  user: null,
  uploadQueue: [],
  uploadSuccessCount: 0,

}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }

    case 'ADD_TOAST':
      return handleToast(state, type, rest)

    case 'ADD_UPLOADS':
      return {...state, uploadQueue: [...state.uploadQueue, ...rest.data]}

    case 'UPDATE_UPLOAD':
      return {...state, uploadQueue: [...state.uploadQueue]}

    case 'REMOVE_UPLOAD_ITEM':
      const index = state.uploadQueue.indexOf(rest.data)

      const newQueue = [...state.uploadQueue]
      if (index > -1) {
        newQueue.splice(index, 1);
      }
      return {...state, uploadQueue: newQueue, uploadSuccessCount: state.uploadSuccessCount+1}

    default:
      return state
  }
}

const store = createStore(changeState, applyMiddleware(thunk))
export default store
